var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-cont-wrap"},[_c('div',{staticClass:"file-cont-inner"},[_vm._m(0),_c('div',{staticClass:"file-content"},[_vm._m(1),_c('div',{staticClass:"file-body"},[_c('div',{staticClass:"file-scroll"},[_c('div',{staticClass:"file-content-inner"},[_c('h4',[_vm._v("Recent Files")]),_c('div',{staticClass:"row row-sm"},_vm._l((_vm.filemanager),function(item){return _c('div',{key:item.id,staticClass:"col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3"},[_c('div',{staticClass:"card card-file"},[_vm._m(2,true),_vm._m(3,true),_c('div',{staticClass:"card-body"},[_c('h6',[_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(item.pdf)+" ")])]),_c('span',[_vm._v(_vm._s(item.size)+" ")])]),_c('div',{staticClass:"card-footer"},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(item.changed)+" ")]),_vm._v(_vm._s(item.changecontent)+" ")])])])}),0),_c('h4',[_vm._v("Files")]),_c('div',{staticClass:"row row-sm"},_vm._l((_vm.filemanager1),function(item){return _c('div',{key:item.id,staticClass:"col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3"},[_c('div',{staticClass:"card card-file"},[_vm._m(4,true),_vm._m(5,true),_c('div',{staticClass:"card-body"},[_c('h6',[_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(item.pdf))])]),_c('span',[_vm._v(_vm._s(item.size))])]),_c('div',{staticClass:"card-footer"},[_vm._v(_vm._s(item.changecontent))])])])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-cont-header"},[_c('div',{staticClass:"file-options"},[_c('a',{staticClass:"file-sidebar-toggle",attrs:{"href":"javascript:void(0)","id":"file_sidebar_toggle"}},[_c('i',{staticClass:"fa fa-bars"})])]),_c('span',[_vm._v("File Manager")]),_c('div',{staticClass:"file-options"},[_c('span',{staticClass:"btn-file"},[_c('input',{staticClass:"upload",attrs:{"type":"file"}}),_c('i',{staticClass:"fa fa-upload"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"file-search"},[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('i',{staticClass:"fa fa-search"})]),_c('input',{staticClass:"form-control rounded-pill",attrs:{"type":"text","placeholder":"Search"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-file"},[_c('a',{staticClass:"dropdown-link",attrs:{"href":"","data-bs-toggle":"dropdown"}},[_c('i',{staticClass:"fa fa-ellipsis-v"})]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("View Details")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Share")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Download")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Rename")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Delete")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-file-thumb"},[_c('i',{staticClass:"fa fa-file-pdf-o"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-file"},[_c('a',{staticClass:"dropdown-link",attrs:{"href":"","data-bs-toggle":"dropdown"}},[_c('i',{staticClass:"fa fa-ellipsis-v"})]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("View Details")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Share")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Download")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Rename")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Delete")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-file-thumb"},[_c('i',{staticClass:"fa fa-file-word-o"})])
}]

export { render, staticRenderFns }