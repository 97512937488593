<template>
  <div>
    <!-- <sidebar> -->
    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner" id="style-15">
        <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
          <div id="sidebar-menu" class="sidebar-menu">
            <ul>
              <li class="menu-title">
                <span>{{ $t("Menü") }}</span>
              </li>
              <!-- <li class="submenu">
                <router-link to="/anasayfa">
                  <a href=""><i class="la la-dashboard"></i> <span> {{ $t("Anasayfa") }}</span></a>
                </router-link>
              
              </li> -->

              <li class="menu">
                <router-link to="/index">
                  <a href="#"
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><span>{{
                      $t("Anasayfa") }}</span></a> </router-link>
              </li>
              <li class="menu">
                <router-link to="/ilan">
                  <a href="#"
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><span>{{
                      $t("Arsa") }}</span></a> </router-link>
              </li><li class="menu">
                <router-link to="/emlak">
                  <a href="#"
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><span>{{
                      $t("Emlak") }}</span></a> </router-link>
              </li>
              <li class="menu">
                <router-link to="/alici">
                  <a href="#"
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><span>{{
                      $t("Alıcılar") }}</span></a> </router-link>
              </li>

              
              <li class="menu">
                <router-link to="/kullanici">
                  <a href="#"
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><span>{{
                      $t("Kullanıcılar") }}</span></a> </router-link>
              </li>

             
              <li class="menu">
                <router-link to="/landArsa">
                  <a href="#" 
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><span>{{
                      $t("Land Arsa") }}</span></a> </router-link>
              </li>
              <li class="menu">
                <router-link to="/landEmlak">
                  <a href="#" 
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><span>{{
                      $t("Land Emlak") }}</span></a> </router-link>
              </li>

 <li class="menu">
                <router-link to="/harita">
                  <a href="#" 
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><span>{{
                      $t("Harita") }}</span></a> </router-link>
              </li>
              <!-- <li class="menu-title">
                <span>{{ $t("Satıcı") }}</span>
              </li>
              <li class="submenu">
                <router-link to="/satici">
                  <a href="#"
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><i
                      class="la la-user"></i> <span> {{ $t("Satıcılar") }}</span> </a>
                </router-link>
              </li>
              <li class="menu-title">
                <span>{{ $t("Arsa") }}</span>
              </li>
              <li class="submenu">
                <router-link to="/arsa">
                  <a href="#"
                    v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"><i
                      class="la la-user"></i> <span> {{ $t("Arsalar") }}</span> </a></router-link>

              </li> -->








            </ul>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <!-- </sidebar> -->
  </div>
</template>
<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
export default {
  components: {
    PerfectScrollbar,
  },
  mounted() {
    $('#sidebar-menu a').on('click', function (e) {
      if ($(this).parent().hasClass('submenu')) {
        e.preventDefault();
      }
      if (!$(this).hasClass('subdrop')) {
        $('ul', $(this).parents('ul:first')).slideUp(350);
        $('a', $(this).parents('ul:first')).removeClass('subdrop');
        $(this).next('ul').slideDown(350);
        $(this).addClass('subdrop');
      } else if ($(this).hasClass('subdrop')) {
        $(this).removeClass('subdrop');
        $(this).next('ul').slideUp(350);
      }
    });
    $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');

  },
  computed: {
    currentPath() {
      return this.$route.name;
    }
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      activeClass: 'active',
    };
    //  isactive : true
  },
  methods: {
    scrollHanle(evt) {

    },
    haritaTemizle(){
      this.$store.commit("Setilanlar",[])

    },
  },
}

</script>
<style>
.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 60px);
  background-color: transparent !important;
}

.sidebar-menu li a.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
