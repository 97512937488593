<template>
  <div class="shiftlist">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">
          <div class="col-auto float-end ms-auto">
            <a href="#" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_shift">Add Shifts</a>
            <!-- <a href="#" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_schedule"> Assign Shifts</a>  -->
          </div>
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">

              <div class="col">
                <h3 class="page-title">Shift List</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/index">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item"><a href="#">Employees</a></li>
                  <li class="breadcrumb-item active">Shift List</li>
                </ul>
              </div>

            </div>

          </div>
          <!-- /Page Header -->


          <div id="add_shift" class="modal custom-modal fade" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Add Shift</h5>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Client Name <span class="text-danger">*</span></label>
                          <div class="input-group  ">
                            <!-- <input v-model="shiftTable.Client" class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span> -->
                            <Select2 name="clientslistData" v-model="shiftTable.Client" :options="getClientData"
                              width="150" placeholder="Please select company" />


                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Shift Name <span class="text-danger">*</span></label>
                          <div class="input-group time timepicker">
                            <input v-model="shiftTable.ShiftName" class="form-control"><span class="input-group-text"><i
                                class="fa fa-clock-o"></i></span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Start Time <span class="text-danger">*</span></label>
                          <div class="input-group time timepicker">
                            <input v-model="shiftTable.StartTime" class="form-control"><span class="input-group-text"><i
                                class="fa fa-clock-o"></i></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>End On <span class="text-danger">*</span></label>
                          <div> <input type="date" v-model="shiftTable.ShiftEndOn" class="picker"
                              placeholder="Please select start date" :editable="true" :clearable="false" /></div>
                        </div>
                      </div>


                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Break Time (In Minutes) </label>
                          <input v-model="shiftTable.BreakTime" type="text" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>End Time <span class="text-danger">*</span></label>
                          <div class="input-group time timepicker">
                            <input v-model="shiftTable.EndTime" class="form-control"><span class="input-group-text"><i
                                class="fa fa-clock-o"></i></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group wday-box">
                          <label class="checkbox-inline"><input type="checkbox" v-model="shiftTable.Days[0]"
                              value="monday" class="days recurring" checked=""><span class="checkmark">M</span></label>
                          <label class="checkbox-inline"><input type="checkbox" value="tuesday" class="days recurring"
                              checked=""><span class="checkmark">T</span></label>
                          <label class="checkbox-inline"><input type="checkbox" value="wednesday" class="days recurring"
                              checked=""><span class="checkmark">W</span></label>
                          <label class="checkbox-inline"><input type="checkbox" value="thursday" class="days recurring"
                              checked=""><span class="checkmark">T</span></label>
                          <label class="checkbox-inline"><input type="checkbox" value="friday" class="days recurring"
                              checked=""><span class="checkmark">F</span></label>
                          <label class="checkbox-inline"><input type="checkbox" value="saturday"
                              class="days recurring"><span class="checkmark">S</span></label>
                          <label class="checkbox-inline"><input type="checkbox" value="sunday"
                              class="days recurring"><span class="checkmark">S</span></label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="col-form-label">Repeat Every</label> &nbsp;
                          <select class="select">
                            <option value="">1 </option>
                            <option value="1">2</option>
                            <option value="2">3</option>
                            <option value="3">4</option>
                            <option selected value="4">5</option>
                            <option value="3">6</option>
                          </select>
                          <label class="col-form-label">Week(s)</label>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="custom-control form-check">
                          <input type="checkbox" class="form-check-input" id="customCheck1">
                          <label class="form-check-label" for="customCheck1">Recurring Shift</label>
                        </div>
                      </div>

                      <div class="col-sm-2">
                        <div class="custom-control form-check">
                          <input type="checkbox" class="form-check-input" id="customCheck2">
                          <label class="form-check-label" for="customCheck2">Indefinite</label>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Add Note </label>
                          <textarea v-model="shiftTable.Note" class="form-control" rows="2"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="submit-section">
                      <button class="btn btn-primary submit-btn" @click.prevent="addShiftTable">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <shifttable @shiftTable="shiftTableAdd" @sendItem="getData" :datas="data" />

        </div>


        <!-- /Page Content -->

      </div>
      <!-- /Page Wrapper github-->


    </div>
  </div>
</template>
<script>

const currentDate = new Date()
const currentDate1 = new Date()

// import DatePicker from 'vue2-datepicker';
import Select2 from "v-select2-component";
import { mapGetters, mapActions } from 'vuex';

import 'bootstrap/dist/css/bootstrap.css'





export default {
  data() {
    return {


      data: {},
      classList: {},
      clients: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: ""
      },
      startdate: currentDate,
      enddate: currentDate1,
      shiftTable: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        ShiftEndOn: "",
        Status: true,
        Days: [],
        editshift: {},
        clientss: [],
      }
    }
  },
  components: {//DatePicker, 
    Select2,


  },
  computed: {
    ...mapGetters(["Getclient"]),


    getClientData() {
      let arr = []
      this.Getclient.forEach(e => {
        arr.push(e.ClientName)
      })
      console.log("DDDD", arr);
      return [...new Set(arr)]

    },


  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "getShiftTable", "fetchdata"]),
    makeToast() {

      this.$bvToast.toast(`This is toast number `, {
        title: 'BootstrapVue Toast',
        autoHideDelay: 10000,

      })
    },


    getData(value) {
      console.log("Daaaa", value);
      this.data = value;
    },

    shiftTableAdd(e) {
      this.shiftTable = e;
    },


    editShift(h) {
      console.log(h);
      this.editshift = h;
    },
    /////EKLE
    addShiftTable() {
      this.AddData({
        db: "ShiftTable",
        mt: "SetShiftTable",
        veri: this.shiftTable,
      }).then(() => {
        this.getShiftTable()

        $(".close").click()
        this.makeToast();
      });

    },
    setOption() {
      let addclientfilter = [];
      this.getClientData.forEach((el) => {
        addclientfilter.push({ title: el.ClientName });
      });

    },





  },

  mounted() {
    this.setOption()
    this.getShiftTable()


  },
  name: 'shiftTable',
}
</script>

<style scoped>
.select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  height: 44px;
  width: 230px;
}
</style>