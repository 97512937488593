<template>
  <div class="faq">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
      
      <!-- Page Content -->
      <div class="content container-fluid">
        
      <faqheader />
          
      <faqcontent />
          
      </div>
      <!-- /Page Content -->
        
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   
  export default {
    components: {
   
    },
    mounted() {
    },
    name: 'faq'
  }
</script>