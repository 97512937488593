<template>
  <div class="employees">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">


        <!-- Page Content -->
        <div class="content container-fluid">

          <employeeheader />

          <employeefilter @userFilter="userFilter" />

          <employeecontent @user="GetUser"
            :personelData="(userFilterData.length == 0 ? GetEmployes : userFilterData)" />
        </div>
        <!-- /Page Content -->

        <employeemodal :userdata="userdata" />

      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      userdata: {},
      userFilterData: []
    }
  },
  components: {

  },
  methods: {

    ...mapActions(["departmanGetir", "tumPersonel"]),
    userFilter(value) {
      this.userFilterData = value;

    },
    GetUser(value) {
      this.userdata = value
    }
  },
  computed: {

    ...mapGetters(["GetEmployes"]),
  },
  mounted() {
    this.departmanGetir()
    this.tumPersonel()
  },
  name: 'employees'
}
</script>