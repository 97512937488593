<template>

  <div>


    <table class="table table-striped custom-table datatable" id="employeelistcontent">
      <thead>
        <tr>
          <th>Name </th>

          <th>Email</th>
          <th>Mobile</th>
          <th class="text-nowrap">Birth Date</th>
          <th>Department</th>
          <th class="text-end no-sort">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in personelData" :key="item.id">
          <td>
            <h2 class="table-avatar">
              <router-link :to="{ name: 'profile', params: { data: item.id } }" class="avatar"><img class="avatar"
                  :src="item.picture" alt="/" />
              </router-link>
              <router-link :to="{ name: 'profile', params: { data: item.id } }">{{ item.FirstName +" "+item.LastName}} <span></span></router-link>

            </h2>
          </td>

          <td>{{ item.Email }}</td>
          <td>{{ item.Phone }}</td>
          <td>{{ item.date }}</td>
          <td>

            <a href="" class="btn btn-white btn-sm btn-rounded ">{{ item.Department }} </a>

          </td>
          <td class="text-end">
            <div class="dropdown dropdown-action">
              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                  class="material-icons">more_vert</i></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"
                  @click="$emit('user', item)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i
                    class="fa fa-trash-o m-r-5"></i> Delete</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
import employeeslist from '../../../../assets/json/employeeslist.json';
import util from '../../../../assets/utils/util'
import { mapActions, mapGetters } from 'vuex'

export default {

  props: ["personelData"],
  data() {
    return {
      veri: [],

    }
  },

  components: {
  },
  methods: {
    ...mapActions(["fetchdata"]),
    fetch() {
      this.fetchdata({ db: "Employee", mt: "SetEmployee" })
    }
  },
  computed: {
    // ...mapGetters(["GetEmployes"]),
    // GetEmployee() {
    //   console.log("GetTempSearch", this.GetTempSearch)
    //   return this.GetEmployes
    // }
  },
  created() {

  },
  mounted() {
    // if (this.GetEmployee.length == 0) {
    //   this.fetch()
    // }



  }
}
</script>