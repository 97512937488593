<template>
  <div class="knowledgebase">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
        
        <header-knowledgebase />
          
        <knowledgebase-content />
          
        </div>
        <!-- /Page Content -->
        
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   import HeaderKnowledgebase from '@/views/pages/knowledgebase/headerknowledgebase.vue'
import KnowledgebaseContent from '@/views/pages/knowledgebase/knowledgebasecontent.vue'
  export default {
    components: {
   HeaderKnowledgebase,
   KnowledgebaseContent
    },
    mounted() {

    },
    name: 'knowledgebase'
  }
</script>