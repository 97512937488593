<template>
  <!-- Add Client Modal -->
  <div>
    <div id="add_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Client</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input
                      class="form-control"
                      v-model="employee.ClientName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Job Title</label>
                    <input
                      class="form-control"
                      v-model="employee.JobTitle"
                      type="text"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      v-model="employee.FirstName"
                      type="text"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input
                      class="form-control"
                      v-model="employee.LastName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      v-model="employee.Email"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input
                      class="form-control"
                      v-model="employee.Phone"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label">Adress </label>
                    <input
                      class="form-control"
                      v-model="employee.Adress"
                      type="textarea"
                    />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <Select2 v-model="employee.Status" :option="addstatus" />
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="edit_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Client</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="EditDatas">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input
                      class="form-control"
                      type="text"
                      name="ClientName"
                      :value="datas.ClientName"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Job Title</label>
                    <input
                      class="form-control"
                      type="text"
                      name="JobTitle"
                      :value="datas.JobTitle"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      name="FirstName"
                      :value="datas.FirstName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input
                      class="form-control"
                      name="LastName"
                      :value="datas.LastName"
                      type="text"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      name="Email"
                      :value="datas.Email"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input
                      class="form-control"
                      name="Phone"
                      :value="datas.Phone"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label">Adress </label>
                    <input
                      class="form-control"
                      :value="datas.Adress"
                      type="textarea"
                    />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <Select2
                      name="Status"
                      :value="datas.Status"
                      :options="addstatus"
                    />
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
    
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Client</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"></button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    class="btn btn-primary continue-btn"
                    @click.prevent="deleteVeri"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                     
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Client Modal -->
</template>
<script>
import "vue2-datepicker/index.css";
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from "vuex";

const currentDate = new Date();
const currentDate1 = new Date();
export default {
  name: "clientslistData",
  props: ["datas"],
  components: {
    Select2,
  },
  data() {
    return {
      name: "select",
      compid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
      addstatus: ["Active", "Passive"],
      startdate: currentDate,
      enddate: currentDate1,
      veri:{},
      employee: {
        ClientName: "",
        JobTitle: "",
        LastName: "",
        FirstName: "",
        Status: true,
        Adress: "",
        Email: "",
        Phone: "",
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
      },
    };
  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "fetchdata","getClients"]),
    SendData() {
      this.AddData({ db: "Client", mt: "SetClient", veri: this.employee }).then(() => {
        this.getClients()
        $(".close").click()
   
      });
    },
    EditDatas(e) {
      console.log(e.target);
      const formData = new FormData(e.target);
      const formProps = Object.fromEntries(formData);
   
      this.EditData({
        db: "Client",
        mt: "ChangeClient",
        data: formProps,
        id: this.datas.id,
      }).then(() => {
        this.getClients()
        $(".close").click()
   
      });
    },
 
    deleteVeri(){
        this.DeleteData({ db: "Client",mt: "DeleteClient",data:this.datas}).then(()=>{
          this.getClients()
          $(".close").click()
        });
        console.log("SİLİNEN", this.veri)
    },


  },
  computed: {},
  watch:{

datas(value){
  this.veri=value
  console.log(this.veri);
}
  },
  mounted() {
    console.log(this.compid);
  },
};
</script>