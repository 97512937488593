var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-0"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"action":"#"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"card-title"},[_vm._v("Personal details")]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("State:")]),_c('vue-select',{attrs:{"options":_vm.basicstate}})],1),_vm._m(3)]),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"card-title"},[_vm._v("Personal details")]),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Country:")]),_c('vue-select',{attrs:{"options":_vm.basiccountry}})],1)]),_vm._m(7)]),_vm._m(8)])]),_vm._m(9)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("Two Column Vertical Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password:")]),_c('input',{staticClass:"form-control",attrs:{"type":"password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Your Message:")]),_c('textarea',{staticClass:"form-control",attrs:{"rows":"5","cols":"5","placeholder":"Enter message"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("First Name:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Last Name:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Phone:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Address line:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("State/Province:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ZIP code:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("City:")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-end"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Submit")])])
}]

export { render, staticRenderFns }