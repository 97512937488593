var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"action":"#"}},[_c('h4',{staticClass:"card-title"},[_vm._v("Personal Information")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Blood Group")]),_c('vue-select',{attrs:{"options":_vm.formbloodgroup}})],1),_vm._m(3)]),_vm._m(4)]),_c('h4',{staticClass:"card-title"},[_vm._v("Postal Address")]),_vm._m(5),_vm._m(6)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("Two Column Vertical Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("First Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Last Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"d-block"},[_vm._v("Gender:")]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"gender","id":"gender_male","value":"option1"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"gender_male"}},[_vm._v("Male")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"gender","id":"gender_female","value":"option2"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"gender_female"}},[_vm._v("Female")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Username")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Repeat Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Address Line 1")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Address Line 2")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("State")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("City")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Country")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Postal Code")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-end"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Submit")])])
}]

export { render, staticRenderFns }