<template>
    <div class="employeedashboard">
        <div class="main-wrapper">
            <main-header></main-header>
            <sidebar></sidebar>

            <div class="page-wrapper">
                <div class="content container-fluid">
                    <welcomeheader />
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
                                    <div class="dash-widget-info">
                                        <h3>{{ arsalar }}</h3>
                                        <span> Arsalar</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
                                    <div class="dash-widget-info">
                                        <h3>{{ emlaklar }}</h3>
                                        <span>Emlaklar </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
                                    <div class="dash-widget-info">
                                        <h3>{{ alicilar }}</h3>
                                        <span>Alıcılar </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
                                    <div class="dash-widget-info">
                                        <h3>{{ personeller }}</h3>
                                        <span>Personeller </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div class="col-md-2 col-sm-2 col-lg-2 col-xl-3"></div>  <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
                        <img :src="`http://images.landex.com.tr/images/${$store.state.klasorAdi}/firmalogo.jpg`" alt=""></div>
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3"></div>

                        <!-- <today /> 
                        <projects />-->
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import Welcomeheader from '@/views/pages/dashboard/employee/welcomeheader.vue'
import Projects from '@/views/pages/dashboard/employee/projects.vue'
import Today from '@/views/pages/dashboard/employee/today.vue'
import { mapActions, mapGetters } from 'vuex';
export default {


    data() {
        return {

            arsalar: 0,
            emlaklar: 0,
            alicilar: 0,
            personeller: 0

        }
    },
    components: {
        // Welcomeheader,
        // Projects,
        // Today,
    },
    methods: {
        ...mapActions(["axiosSorgu", "axiosIslem"]),

    },

    mounted() {
        this.axiosSorgu({
            tablo: "ilanlarSayi",
            page: 1,
            limit: 100000,
            sorgu: {},
        })
            .then(response => {
                this.arsalar = response.data
            })


        this.axiosSorgu({
            tablo: "emlakSayi",
            page: 1,
            limit: 100000,
            sorgu: {},
        })
            .then(response => {
                this.emlaklar = response.data
            })


            this.axiosSorgu({
            tablo: "aliciSayi",
            page: 1,
            limit: 100000,
            sorgu: {},
        })
            .then(response => {
                this.alicilar = response.data
            })


            this.axiosSorgu({
            tablo: "personelSayi",
            page: 1,
            limit: 100000,
            sorgu: {},
        })
            .then(response => {
                this.personeller = response.data
            })



    },
    name: 'employeedashboard'
}
</script>