<template>
  <div class="formbasicinputs">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
       <div class="content container-fluid">
        
       <headerbasicinputs />
          
       <basicinputform />
        
        </div>      
      </div>
      <!-- /Main Wrapper -->
    </div>
  </div>
</template>
<script>
   
  export default {
    components: {
   
    },
    mounted() {
    },
    name: 'formbasicinputs'
  }
</script>