var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"add_employee","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('form',[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Status")]),_c('vue-select',{attrs:{"options":_vm.addexperiencestatus}})],1)])]),_vm._m(2)])])])])]),_c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"edit_job","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(3),_c('div',{staticClass:"modal-body"},[_c('form',[_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Status")]),_c('vue-select',{attrs:{"options":_vm.editexperiencestatus}})],1)])]),_vm._m(5)])])])])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Add Experience Level")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Experience Level "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-section"},[_c('button',{staticClass:"btn btn-primary submit-btn"},[_vm._v("Submit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Edit Candidates")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Experience Level "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"1-3"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-section"},[_c('button',{staticClass:"btn btn-primary submit-btn"},[_vm._v("Save")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"delete_job","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-header"},[_c('h3',[_vm._v("Delete")]),_c('p',[_vm._v("Are you sure want to delete?")])]),_c('div',{staticClass:"modal-btn delete-action"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a',{staticClass:"btn btn-primary continue-btn",attrs:{"href":"javascript:void(0);"}},[_vm._v("Delete")])]),_c('div',{staticClass:"col-6"},[_c('a',{staticClass:"btn btn-primary cancel-btn",attrs:{"href":"javascript:void(0);","data-bs-dismiss":"modal"}},[_vm._v("Cancel")])])])])])])])])
}]

export { render, staticRenderFns }