var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover custom-table datatable mb-0"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_c('h2',{staticClass:"table-avatar"},[_c('router-link',{staticClass:"avatar",attrs:{"to":"/client-profile"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-19.jpg"),"alt":""}})]),_c('router-link',{attrs:{"to":"/client-profile"}},[_vm._v("Global Technologies")])],1)]),_c('td',[_vm._v("Free Trial")]),_c('td',[_vm._v("30")]),_c('td',[_vm._v("6 Months")]),_c('td',[_vm._v("15 Feb 2019")]),_c('td',[_vm._v("14 Jul 2019")]),_c('td',[_vm._v("$200")]),_vm._m(1),_vm._m(2),_vm._m(3)]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_c('h2',{staticClass:"table-avatar"},[_c('router-link',{staticClass:"avatar",attrs:{"to":"/client-profile"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-29.jpg"),"alt":""}})]),_c('router-link',{attrs:{"to":"/client-profile"}},[_vm._v("Delta Infotech")])],1)]),_c('td',[_vm._v("Premium")]),_c('td',[_vm._v("50")]),_c('td',[_vm._v("1 Year")]),_c('td',[_vm._v("15 Feb 2019")]),_c('td',[_vm._v("14 Feb 2020")]),_c('td',[_vm._v("$200")]),_vm._m(4),_vm._m(5),_vm._m(6)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Client")]),_c('th',[_vm._v("Plan")]),_c('th',[_vm._v("Users")]),_c('th',[_vm._v("Plan Duration")]),_c('th',[_vm._v("Start Date")]),_c('th',[_vm._v("End Date")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Plan Status")]),_c('th',[_vm._v("Update Plan")]),_c('th',[_vm._v("Status")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"badge bg-inverse-success"},[_vm._v("Active")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":"javascript:void(0);","data-bs-toggle":"modal","data-bs-target":"#upgrade_plan"}},[_vm._v("Change Plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"status-toggle"},[_c('input',{staticClass:"check",attrs:{"type":"checkbox","id":"company_status_1"}}),_c('label',{staticClass:"checktoggle",attrs:{"for":"company_status_1"}},[_vm._v("checkbox")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"badge bg-inverse-danger"},[_vm._v("Expired")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":"javascript:void(0);","data-bs-toggle":"modal","data-bs-target":"#upgrade_plan"}},[_vm._v("Change Plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"status-toggle"},[_c('input',{staticClass:"check",attrs:{"type":"checkbox","id":"company_status_2","checked":""}}),_c('label',{staticClass:"checktoggle",attrs:{"for":"company_status_2"}},[_vm._v("checkbox")])])])
}]

export { render, staticRenderFns }