<template>
<!-- Search Filter -->
          <div class="row filter-row mb-4">
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <input class="form-control floating" type="text">
                <label class="focus-label">Employee</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3"> 
              <div class="form-group form-focus select-focus">
                <vue-select :options="employeereportdepartment" />
                <label class="focus-label">Department</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <div class="cal-icon">
                          <datepicker v-model="startdate"  class="picker1" 
                          :editable="true"
                          :clearable="false" />
                    </div>
               
              </div>
            </div>
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <div class="cal-icon">
                          <datepicker v-model="enddate"  class="picker1" 
                          :editable="true"
                          :clearable="false" />
                    </div>
                
              </div>
            </div>
            <div class="col-sm-6 col-md-3">  
              <div class="d-grid">
                <a href="#" class="btn btn-success"> Search </a>  
              </div>
            </div>     
          </div>
          <!-- /Search Filter -->
</template>
<script>
   
  
  const currentDate = new Date()
  const currentDate1 = new Date()
  export default {
    data() {
    return {
      employeereportdepartment: ["Select Department", "Designing", "Development", "Finance", "Hr & Finance"],
      startdate: currentDate,
      enddate: currentDate1,
    }
    },
    components: {
   
    },
    mounted() {
    },
  }
</script>