<template>
<div>


<div class="job-content job-widget" v-for="item in jobdetaildescribe" :key="item.id">
                <div class="job-desc-title"><h4>{{item.jobtitle}}</h4></div>
                <div class="job-description">
                  <p>{{item.jobcontent}}</p>
                  <ul class="square-list">
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                  </ul>
                </div>
              </div>
              </div>
</template>
<script>
  import jobdetaildescribe from '../../../../assets/json/jobdetaildescribe.json';
   
  export default {
    data() {
    return {
    jobdetaildescribe: jobdetaildescribe
    }
    },
    components: {
   
    },
  }
</script>