<template>
  <div class="trainers">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">

          <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Trainers</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/index">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item active">Trainers</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_trainer"><i
                    class="fa fa-plus"></i> Add New</a>
              </div>
            </div>
          </div>
          <!-- /Page Header -->

          <trainerstable @AddData="trainer" @SendItem="getData" />

        </div>
        <!-- /Page Content -->

        <trainermodal @AddData="trainier" :datas="data" />

      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import Select2 from 'v-select2-component';
import { mapGetters, mapActions } from 'vuex';
/* eslint-disable */
export default {
  data() {
    return {

      trainner: {
        CompanyUID: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        Description: "",
        Email: "",
        EmployeID: "",
        FirstName: "",
        LastName: "",
        Phone: "",
        Role: "",
        Status: true,
      },
      data: {},
      deleteTrainier: {},
      editTrainer: {},
      addtrainertatus: ["Active", "Inactive"],
      edittrainerstatus: ["Active", "Inactive"],

    };
  },
  components: {
    Select2,
  },
  methods: {
    ...mapActions(["AddData", "fetchdata", "EditData", "DeleteData", "GetTrainer"]),
    fetch() {
      this.fetchdata({ db: "Trainer", mt: "SetTrainer" });
    },
    addTrainier() {
      this.AddData({
        db: "Trainer",
        mt: "SetTrainer",
        veri: this.trainner,
      }).then(() => {
        this.GetTrainer()
        $(".close").click()
      });

    },
    trainer(e) {
      this.trainerType = e;
    },
    // delTrainer(b) {
    //   this.deleteTrainer = b;
    //   console.log(b);
    // },
    editTrainer(h) {
      this.editTrainers = h;
    },
    getData(value) {
      console.log(value);
      this.data = value
    }
  },
  mounted() {
    this.GetTrainer()
  },
  name: 'trainers'
}
</script>