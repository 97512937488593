import {
  initializeApp
} from 'firebase/app';

import {
  getFirestore
} from 'firebase/firestore';

import resim from 'firebase/storage'
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCF5em6pCPutw7RXRjSUJmfpKqJRy1WAtY",
  authDomain: "emlakarsa-7baa8.firebaseapp.com",
  projectId: "emlakarsa-7baa8",
  storageBucket: "emlakarsa-7baa8.appspot.com",
  messagingSenderId: "918084054490",
  appId: "1:918084054490:web:47df0c093315058123a4e3"
};

// Initialize firebase and then firestore of that instance
export const conf = initializeApp(firebaseConfig);
export const db = getFirestore(conf);
export const storage = resim;
