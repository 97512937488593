<template>
  <!-- Search Filter -->
  <div class="row filter-row">
    <div class="col-sm-6 col-md-3">
      <div class="form-group form-focus">
        <input type="text" class="form-control floating">
        <label class="focus-label">Personel</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="form-group form-focus select-focus">
        <Select2 v-model="selectMonth" :options="attendancefilter" />
        <label class="focus-label">Select Month</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="form-group form-focus select-focus">
        <Select2 v-model="selectYear" :options="attendanceyear" />
        <label class="focus-label">Select Year</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="d-grid">
        <a href="#" @click="ara()" class="btn btn-success"> Search </a>
      </div>
    </div>
  </div>
  <!-- /Search Filter -->
</template>
<script>
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
export default {
  data() {
    return {
      attendancefilter: ["-", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      attendanceyear: ["-", "2022", "2021", "2020", "2019", "2018"],
      selectYear: "",
      selectMonth: ""

    }
  },
  components: {

    Select2
  },
  methods: {
    ara() {


      let seciliGun = new Date(this.selectYear, this.attendancefilter.indexOf(this.selectMonth), 0).getDate()
      this.$emit("seciliGun", { selectedYear: this.selectYear, seletedMonth: this.attendancefilter.indexOf(this.selectMonth), Day: seciliGun })

    }

  },
  mounted() {
  }
}
</script>