var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped custom-table mb-0 datatable",attrs:{"id":"maintickettable"}},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_c('router-link',{attrs:{"to":"/ticket-view"}},[_vm._v("#TKT-0001")])],1),_c('td',[_vm._v("Laptop Issue")]),_c('td',[_c('h2',{staticClass:"table-avatar"},[_c('router-link',{staticClass:"avatar avatar-xs",attrs:{"to":"/profile"}},[_c('img',{attrs:{"alt":"","src":require("../../../../assets/img/profiles/avatar-10.jpg")}})]),_c('a',{attrs:{"href":"#"}},[_vm._v("John Smith")])],1)]),_c('td',[_vm._v("5 Jan 2019 07:21 AM")]),_c('td',[_vm._v("5 Jan 2019 11.12 AM")]),_vm._m(1),_vm._m(2),_vm._m(3)]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_c('router-link',{attrs:{"to":"/ticket-view"}},[_vm._v("#TKT-0001")])],1),_c('td',[_vm._v("Internet Issue")]),_c('td',[_c('h2',{staticClass:"table-avatar"},[_c('router-link',{staticClass:"avatar avatar-xs",attrs:{"to":"/profile"}},[_c('img',{attrs:{"alt":"","src":require("../../../../assets/img/profiles/avatar-08.jpg")}})]),_c('a',{attrs:{"href":"#"}},[_vm._v("Catherine Manseau")])],1)]),_c('td',[_vm._v("5 Jan 2019 07:21 AM")]),_c('td',[_vm._v("5 Jan 2019 11.12 AM")]),_vm._m(4),_vm._m(5),_vm._m(6)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Ticket Id")]),_c('th',[_vm._v("Tickefddt Subject")]),_c('th',[_vm._v("Assigned Staff")]),_c('th',[_vm._v("Created Date")]),_c('th',[_vm._v("Last Reply")]),_c('th',[_vm._v("Priority")]),_c('th',{staticClass:"text-center"},[_vm._v("Status")]),_c('th',{staticClass:"text-end"},[_vm._v("Actions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"dropdown action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" High ")]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" High")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-warning"}),_vm._v(" Medium")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" Low")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"dropdown action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" New ")]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-info"}),_vm._v(" Open")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-info"}),_vm._v(" Reopened")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" On Hold")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" Closed")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" In Progress")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" Cancelled")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-end"},[_c('div',{staticClass:"dropdown dropdown-action"},[_c('a',{staticClass:"action-icon dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#edit_ticket"}},[_c('i',{staticClass:"fa fa-pencil m-r-5"}),_vm._v(" Edit")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#delete_ticket"}},[_c('i',{staticClass:"fa fa-trash-o m-r-5"}),_vm._v(" Delete")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"dropdown action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-warning"}),_vm._v(" Medium ")]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" High")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-warning"}),_vm._v(" Medium")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" Low")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"dropdown action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-info"}),_vm._v(" Reopened ")]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-info"}),_vm._v(" Open")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-info"}),_vm._v(" Reopened")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" On Hold")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" Closed")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" In Progress")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" Cancelled")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-end"},[_c('div',{staticClass:"dropdown dropdown-action"},[_c('a',{staticClass:"action-icon dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#edit_ticket"}},[_c('i',{staticClass:"fa fa-pencil m-r-5"}),_vm._v(" Edit")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#delete_ticket"}},[_c('i',{staticClass:"fa fa-trash-o m-r-5"}),_vm._v(" Delete")])])])])
}]

export { render, staticRenderFns }