<template>
  <div class="index">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
        <!-- Page Content -->
        <div class="content container-fluid">

        <page-header />

        <widget />

        <chart />

        <employee />

        <statistics />

        <invoices />

        <clients />

        </div>
        <!-- /Page Content -->
        </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   import PageHeader from '@/views/pages/dashboard/admin/pageheader.vue'
import Widget from '@/views/pages/dashboard/admin/widget.vue'
import Chart from '@/views/pages/dashboard/admin/chart.vue'
import Employee from '@/views/pages/dashboard/admin/employee.vue'
import Statistics from '@/views/pages/dashboard/admin/statistics.vue'
import Invoices from '@/views/pages/dashboard/admin/invoices.vue'
import Clients from '@/views/pages/dashboard/admin/clients.vue'
  export default {
    components: {
PageHeader,
Widget,
Employee,
Chart,
Statistics,
Invoices,
Clients,
    },
    mounted() {
      console.log(this.$route.meta);
    },
    methods: {
    },
    name: 'index'
  }
</script>
