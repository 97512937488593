<template>
  <!-- Search Filter -->
  <div class="row filter-row">
    <!-- <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <input type="text" class="form-control floating">
                <label class="focus-label">Employee ID</label>
              </div>
            </div> -->
    <div class="col-sm-6 col-md-3">
      <div class="form-group form-focus">
        <input type="text" v-model="ara" @keyup="personelAra()" class="form-control floating">
        <label class="focus-label">Personel Name</label>
      </div>
    </div>
    <!-- <div class="col-sm-6 col-md-3">
      <div class="form-group form-focus select-focus">
        <vue-select :options="designationemployeefilter" />
        <label class="focus-label">Designation</label>
      </div>
    </div> -->
    <!-- <div class="col-sm-6 col-md-3">
      <div class="d-grid">
        <a href="#" class="btn btn-success w-100"> Search </a>
      </div>
    </div> -->


  </div>


  <!-- Search Filter -->
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      ara: "",

      designationemployeefilter: ["Select Designation", "Web Developer", "Web Designer", "Android Developer", "Ios Developer"]
    }
  }, components: {


  },

  methods: {



    personelAra() {

      // console.log("PERSONEL SONUÇ", this.GetEmployes)

      this.$emit("userFilter", this.GetEmployes.filter(t => t.FirstName.toUpperCase().indexOf(this.ara.toUpperCase()) != -1))
      //console.log("PERSONELLER", this.GetEmployes.filter(t => t.FirstName.indexOf(this.ara) != -1))
      //   this.$store.co

    }

  },

  computed: {
    // ...mapMutations(["SetEmployee", "SetTempSearch"]),
    ...mapGetters(["GetEmployes"]),


  },

  mounted() {

    // console.log("PERSONELLER", this.$store.state.employee)
  }

}
</script>