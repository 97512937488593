var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"add_addition","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('form',[_vm._m(1),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('vue-select',{attrs:{"options":_vm.payrolladdcategory}})],1),_vm._m(3),_vm._m(4),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"d-block"},[_vm._v("Assignee")]),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"form-group"},[_c('vue-select',{attrs:{"options":_vm.payrolladduser}})],1)]),_vm._m(8)])])])])]),_c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"edit_addition","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(9),_c('div',{staticClass:"modal-body"},[_c('form',[_vm._m(10),_c('div',{staticClass:"form-group"},[_vm._m(11),_c('vue-select',{attrs:{"options":_vm.payrolleditcategory}})],1),_vm._m(12),_vm._m(13),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"d-block"},[_vm._v("Assignee")]),_vm._m(14),_vm._m(15),_vm._m(16),_c('div',{staticClass:"form-group"},[_c('vue-select',{attrs:{"options":_vm.payrolledituser}})],1)]),_vm._m(17)])])])])]),_vm._m(18)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Add Addition")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"d-block"},[_vm._v("Unit calculation")]),_c('div',{staticClass:"status-toggle"},[_c('input',{staticClass:"check",attrs:{"type":"checkbox","id":"unit_calculation"}}),_c('label',{staticClass:"checktoggle",attrs:{"for":"unit_calculation"}},[_vm._v("checkbox")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Unit Amount")]),_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}}),_c('span',{staticClass:"input-group-text"},[_vm._v(".00")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"addition_assignee","id":"addition_no_emp","value":"option1","checked":""}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"addition_no_emp"}},[_vm._v(" No assignee ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"addition_assignee","id":"addition_all_emp","value":"option2"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"addition_all_emp"}},[_vm._v(" All employees ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"addition_assignee","id":"addition_single_emp","value":"option3"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"addition_single_emp"}},[_vm._v(" Select Employee ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-section"},[_c('button',{staticClass:"btn btn-primary submit-btn"},[_vm._v("Submit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Edit Addition")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"d-block"},[_vm._v("Unit calculation")]),_c('div',{staticClass:"status-toggle"},[_c('input',{staticClass:"check",attrs:{"type":"checkbox","id":"edit_unit_calculation"}}),_c('label',{staticClass:"checktoggle",attrs:{"for":"edit_unit_calculation"}},[_vm._v("checkbox")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Unit Amount")]),_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}}),_c('span',{staticClass:"input-group-text"},[_vm._v(".00")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"edit_addition_assignee","id":"edit_addition_no_emp","value":"option1","checked":""}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"edit_addition_no_emp"}},[_vm._v(" No assignee ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"edit_addition_assignee","id":"edit_addition_all_emp","value":"option2"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"edit_addition_all_emp"}},[_vm._v(" All employees ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"edit_addition_assignee","id":"edit_addition_single_emp","value":"option3"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"edit_addition_single_emp"}},[_vm._v(" Select Employee ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-section"},[_c('button',{staticClass:"btn btn-primary submit-btn"},[_vm._v("Save")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"delete_addition","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-header"},[_c('h3',[_vm._v("Delete Addition")]),_c('p',[_vm._v("Are you sure want to delete?")])]),_c('div',{staticClass:"modal-btn delete-action"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a',{staticClass:"btn btn-primary continue-btn",attrs:{"href":"javascript:void(0);"}},[_vm._v("Delete")])]),_c('div',{staticClass:"col-6"},[_c('a',{staticClass:"btn btn-primary cancel-btn",attrs:{"href":"javascript:void(0);","data-bs-dismiss":"modal"}},[_vm._v("Cancel")])])])])])])])])
}]

export { render, staticRenderFns }