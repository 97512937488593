<template>
  <div>

    <!-- Add Holiday Modal -->
    <div class="modal custom-modal fade" id="add_holiday" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Holiday</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label>Holiday Name <span class="text-danger">*</span></label>
                <input v-model="holidayData.Title" class="form-control" type="text" />
              </div>
              <div class="form-group">
                <label>Date <span class="text-danger">*</span></label>
                <input disabled class="form-control" v-model="holidayData.Date" type="text" name="Date" />
              </div>
              <div class="form-group">
                <label>Holiday Start Date <span class="text-danger">*</span></label>
                <input type="date"  class="form-control"  v-model="holidayData.startdate" 
                  />
                 
              </div>
              <div class="form-group">
                <label>Holiday End Date <span class="text-danger">*</span></label>
                <input type="date"  class="form-control"   @change="getDifferenceInDays()" v-model="holidayData.enddate"
                   />
              
              </div>
              <div class="submit-section">
                <button @click.prevent="addHoliday" class="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Holiday Modal -->

    <!-- Edit Holiday Modal -->
    <div class="modal custom-modal fade" id="edit_holiday" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Holiday</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


            <form @submit.prevent="editHoliday">
              <input type="hidden" name="id" v-model="holidayData.id">
              <div class="form-group">
                <label>Holiday Name <span class="text-danger">*</span></label>
                <input class="form-control" v-model="holidayData.Title" type="text" name="Title" />
              </div>
              <div class="form-group">
                <label>Date <span class="text-danger">*</span></label>
                <input disabled class="form-control" v-model="holidayData.Date" type="text" name="Date" />
              </div>
              <div class="form-group">
                <label>Holiday Start Date <span class="text-danger">*</span></label>
                <input type="date"  class="form-control" v-model="holidayData.startdate" />
             
              </div>
              <div class="form-group">
                <label>Holiday End Date <span class="text-danger">*</span></label>
                <input type="date"  class="form-control" @change="getDifferenceInDays()" v-model="holidayData.enddate"
                  />
             
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Holiday Modal -->

    <!-- Delete Holiday Modal -->
    <div class="modal custom-modal fade" id="delete_holiday" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Holiday</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6" @click.prevent="deleteHoliday">
                  <a class="btn btn-primary continue-btn">Delete</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Holiday Modal -->
</template>
<script>
import DatePicker from "vue2-datepicker";
import { mapActions } from "vuex";
const currentDate = new Date();
//const currentDate1 = new Date();
/* eslint-disable */
export default {
  components: {
    DatePicker,
  },
  props: {
    holidays: {
      type: Array,
    },
    editHolidays: {
      type: Object,
    },
    deleteHolidays: {
      type: Object,
    },
  },

  data() {
    return {
      holidayData: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,

      },



    };
  },
  watch: {
    editHolidays(value) {

      this.holidayData = value
    }
  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "sorgu"]),
    addHoliday() {
      console.log("HOLİDAYS", this.holidayData)


      this.AddData({

        db: "Holidays",
        mt: "SetHolidays",
        veri: this.holidayData,
      }).then(() => {

        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] })

        $(".close").click()

      });

    },
    getDifferenceInDays() {

      this.holidayData.Date = Math.abs(new Date(this.holidayData.startdate) - new Date(this.holidayData.enddate)) / (1000 * 60 * 60 * 24);

    },

    deleteHoliday() {
      console.log(this.deleteHolidays.id);
      this.DeleteData({ db: "Holidays", mt: "DeleteHoli", data: this.deleteHolidays }).then(() => {

        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] })

        $(".close").click()

      });
    },
    async editHoliday(e) {
      // const formData = new FormData(e.target);
      // const formProps = Object.fromEntries(formData);
      // console.log(formProps)

      console.log("HOLİDAYS", this.holidayData)
      await this.EditData({ db: "Holidays", data: this.holidayData, id: this.editHolidays.id })

      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      this.sorgu({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] })

      $(".close").click()
    },
  },

  mounted() { },
};
</script>
 