<template>

  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped custom-table mb-0 datatable" id="leavecontent">
          <thead>
            <tr>
              <th>Personel</th>
              <th>Leave Type</th>
              <th width="50">From</th>
              <th>To</th>
              <th>No of Days</th>
              <!-- <th>Reason</th> -->
              <th class="text-center">Status</th>
              <th class="text-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in GetLeaves" :key="item.id">
              <!-- <td>
                <h2 class="table-avatar">
                 <router-link to="/profile" class="avatar"><img alt="" :src="loadImg(item.image)"></router-link>
                  <a href="#">{{ item.name }} <span>{{ item.job }}</span></a> 
                </h2>
              </td> -->
              <td>{{ item.Personel }}</td>

              <td>{{ item.LeaveType }}</td>

              <td>{{
              item.startdate.toDate().toDateString()
              
              
              }}</td>
              <td>{{
              item.enddate.toDate().toDateString()
              
              
              }}</td>
              <td>{{ item.Day }}</td>
              <!-- <td>{{ item.LeaveReason }}</td> -->
              <td class="text-center">
                <div class="dropdown action-label">
                  <a class="btn btn-white btn-sm btn-rounded " href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa fa-dot-circle-o text-purple"></i> {{ item.status }}
                  </a>
                  <!-- <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-purple"></i> Planned </a>
                    <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-info"></i> UnPlanned</a>
                    <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-info"></i> Pending</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#approve_leave"><i
                        class="fa fa-dot-circle-o text-success"></i> Accepted</a>
                    <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Declined</a>
                  </div> -->
                </div>
              </td>
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" @click="$emit('editLeave', item)" href="#" data-bs-toggle="modal"
                      data-bs-target="#edit_leave"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" @click="$emit('editLeave', item)" href="#" data-bs-toggle="modal"
                      data-bs-target="#delete_approve"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import leavecontent from '../../../../assets/json/leavecontent.json';
import util from '../../../../assets/utils/util'
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
import { mapGetters } from 'vuex';
/* eslint-disable */
const images = require.context('../../../../assets/img/profiles', false, /\.png$|\.jpg$/)

export default {
  data() {
    return {
      leavecontent: leavecontent
    }
  },
  components: {
    Select2,
    DatePicker
  },
  mounted() {
    util.datatable('#leavecontent')
  },
  computed: {
    ...mapGetters(["GetLeaves"])

  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
      }
    },
    loadImg(imgPath) {
      return images('./' + imgPath).default
    },
    zaman(a) {
      var date = a;
      return date.getDay() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

    }
  }
}
</script>