import { render, staticRenderFns } from "./jobdetailscontent.vue?vue&type=template&id=9c5f7f20"
import script from "./jobdetailscontent.vue?vue&type=script&lang=js"
export * from "./jobdetailscontent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports