<template>
  <!-- Add Leave Modal -->
  <div>
    <div id="add_leave" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Leave</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">
              <input type="hidden" name="Status" value="0">
              <div class="form-group">
                <label>Personel <span class="text-danger">*</span></label>
                <Select2 :options="GetPersonel" v-model="leaveData.Personel" />
              </div>
              <div class="form-group">
                <label>Leave Type <span class="text-danger">*</span></label>
                <Select2 :options="addleave" name="LeaveType" v-model="leaveData.LeaveType" />
              </div>
              <div class="form-group">
                <label>From <span class="text-danger">*</span></label>
                <div>
                  <input type="date" v-model="leaveData.startdate" class="form-control" :editable="true" :clearable="false" />
                </div>
              </div>
              <div class="form-group">
                <label>To <span class="text-danger">*</span></label>
                <div>
                  <input type="date" v-model="leaveData.enddate" name="EndDate" class="form-control" :editable="true"
                    :clearable="false" />
                </div>
              </div>
              <div class="form-group">
                <label>Number of days <span class="text-danger">*</span></label>
                <input class="form-control" v-model="leaveData.Day" type="text">
              </div>
              <div class="form-group">
                <label>Remaining Leaves <span class="text-danger">*</span></label>
                <input class="form-control" v-model="leaveData.RemainingLeaves" name="RemainintLeaves" readonly
                  value="12" type="text">
              </div>
              <!-- <div class="form-group">
                <label>Leave Reason <span class="text-danger">*</span></label>
                <textarea rows="4" name="LeaveReason" v-model="leaveData.LeaveReason" class="form-control"></textarea>
              </div> -->
              <div class="form-group">
                <label>Status <span class="text-danger">*</span></label>
                <Select2 :options="status" v-model="leaveData.status" />
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Leave Modal -->

    <!-- Edit Leave Modal -->
    <div id="edit_leave" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Leave</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


            <form @submit.prevent="leaveEdit">

              <div class="form-group">
                <label>Personel <span class="text-danger">*</span></label>
                <input class="form-control" v-model="leaveData.Personel" disabled />
              </div>
              <div class="form-group">
                <label>Leave Type <span class="text-danger">*</span></label>
                <Select2 :options="addleave" v-model="leaveData.LeaveType" />
              </div>
              <div class="form-group">
                <label>From <span class="text-danger">*</span></label>
                <div>
                  <input type="date" class="form-control" @change="getDifferenceInDays()" name="startDate"
                    v-model="leaveData.startdate" :editable="true" :clearable="false" />
                </div>
              </div>
              <div class="form-group">
                <label>To <span class="text-danger">*</span></label>
                <div>
                  <input type="date" @change="getDifferenceInDays()" v-model="leaveData.enddate" name="enddate" class="form-control"
                    :editable="true" :clearable="false" />
                </div>
              </div>
              <div class="form-group">
                <label>Number of days <span class="text-danger">*</span></label>
                <input class="form-control" v-model="leaveData.Day" readonly type="text">
              </div>
              <div class="form-group">
                <label>Remaining Leaves <span class="text-danger">*</span></label>
                <input class="form-control" v-model="leaveData.RemainingLeaves" readonly type="text">
              </div>
              <!-- <div class="form-group">
                <label>Leave Reason <span class="text-danger">*</span></label>
                <textarea rows="4" name="LeaveReason" v-model="leaveData.LeaveReason"
                  class="form-control">Going to hospital</textarea>
              </div> -->
              <div class="form-group">
                <label>Status <span class="text-danger">*</span></label>
                <Select2 :options="status" v-model="leaveData.status" />
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Leave Modal -->

    <!-- Approve Leave Modal -->
    <div class="modal custom-modal fade" id="approve_leave" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Leave Approve</h3>
              <p>Are you sure want to approve for this leave?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" class="btn btn-primary continue-btn">Approve</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Decline</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Approve Leave Modal -->

    <!-- Delete Leave Modal -->
    <div class="modal custom-modal fade" id="delete_approve" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Leave</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Are you sure want to delete this leave?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" @click.prevent="leaveDelete"
                    class="btn btn-primary continue-btn">Delete</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Leave Modal -->
</template>
<script>
 /* eslint-disable */
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
import { mapActions, mapGetters } from 'vuex';


const currentDate = new Date()
const currentDate1 = new Date()
const currentDate2 = new Date()
const currentDate3 = new Date()
export default {
  props: {

    editLeave: {
      type: Object
    }
  },
  data() {
    return {
      addleave: ["Select Leave Type", "Casual Leave 12 Days", "Medical Leave", "Loss of Pay"],
      editleavetype: ["Select Leave Type", "Casual Leave 12 Days"],
      status: ["Pending", "Planned", "UnPlanned", "Accepted", "Declined"],
      leaveData: {}
      // leaveData: {
      //   Personel: "",
      //   LeaveReason: "",
      //   RemainingLeaves: "",
      //   Enddate: "",
      //   Day: "",
      //   startdate: "",
      //   LeaveType: "",
      //   CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,

      // },
    }
  },

  components: {
    Select2,
    DatePicker

  },
  methods: {
    ...mapActions(["AddData", "leavesGetir", "EditData", "DeleteData"]),
    SendData(e) {
      const formData = new FormData(e.target);
      const formProps = Object.fromEntries(formData);
      console.log("EKLE", this.leaveData)
      this.AddData({ db: "Leaves", mt: "SetLeaves", veri: this.leaveData }).then(p => {
        $(".close").click();
        this.leavesGetir();
      })
    },
    getDifferenceInDays() {

      this.leaveData.Day = Math.abs(new Date(this.leaveData.startdate) - new Date(this.leaveData.enddate)) / (1000 * 60 * 60 * 24);

    },

    leaveDelete() {

      this.DeleteData({ db: "Leaves", data: this.leaveData }).then(() => {
        this.leavesGetir();
        $(".close").click()

      });
    },

    async leaveEdit(e) {

      console.log(this.leaveData)
      // this.leaveData.startdate1 = this.leaveData.startdate
      await this.EditData({ db: "Leaves", mt: "SetLeaves", data: this.leaveData, id: this.leaveData.id })
      this.leavesGetir();
      $(".close").click()
    },

  },
  watch: {
    editLeave(value) {
      this.leaveData = value;
      this.leaveData.startdate = value.startdate.toDate()
      this.leaveData.enddate = value.enddate.toDate()
      console.log("DEĞİŞEN DATA", value)
    }

  },
  computed: {
    ...mapGetters(["GetEmployes", "GetLeaves"]),
    GetPersonel() {

      return this.GetEmployes.map(a => a.FirstName + " " + a.LastName)
    }
  },
  mounted() {


  }
}
</script>