<template>
  <div class="error404 error-page" style="height: 100vh;">
    <!-- Main Wrapper -->
        <div class="main-wrapper">
      
      <div class="error-box">
        <h1>404</h1>
        <h3><i class="fa fa-warning"></i> Oops! Sayfa bulunamadı!</h3>
       <p></p>
        <router-link to="/index" class="btn btn-custom">Anasayfaya Dön</router-link>
      </div>
    
        </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
   
  export default {
    components: {
   
    },
    mounted() {
    },
    name: 'error404'
  }
</script>