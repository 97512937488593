<template>
  <!-- <div id="app">   
    <div> -->
  <KeepAlive include="ilan,alici">
    <router-view></router-view>
  </KeepAlive>
  <!-- </div>
 </div> -->
</template>

<script>


export default {
  name: "App",


};
</script> 