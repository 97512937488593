<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped custom-table mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Holiday Start Date</th>
              <th>Holiday End Date</th>
              <th>Day</th>
              <th class="text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in Getholidays" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ item.Title }}</td>
              <td>{{ item.startdate }}</td>
              <td>{{ item.enddate }}</td>
              <td>{{ item.Date }}</td>
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_holiday"
                      @click="$emit('editHolidays', item)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_holiday"
                      @click="$emit('deleteHolidays', item)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from "vue";
export default {
  data() {
    return {
      holidays: [],
    };
  },

  methods: {

    // editHolidays(e) {
    //   this.$emit("editHolidays", e);
    // },
    // deleteHolidays(f) {
    //   console.log(f);
    //   this.$emit("deleteHolidays", f);
    // }
  },
  components: {},
  computed: {
    ...mapGetters(["Getholidays"]),

  },
  mounted() {
    // if (this.Getholidays.length == 0) { this.fetchdata(); }
  },

};
</script>
