<template>
  <li class="nav-item dropdown has-arrow main-drop">
    <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
      <span class="user-img me-1">
        <img src="../assets/img/logo4.png" alt="">
        <span class="status online"></span>
      </span>
      <span class="adminclass">{{kullaniciGetir}}</span>
    </a>
    <div class="dropdown-menu">
      <!-- <router-link class="dropdown-item" to="/profile">My Profile</router-link>
      <router-link class="dropdown-item" to="/settings">Ayarlar</router-link> -->



     
      <a href="#" class="dropdown-item"  @click.prevent="cikis()">Çıkış</a>
    </div>
  </li>
</template>

<script>
export default {
  name: "alici",
  data() {
    return {
     
     
    }
  },

  
  methods: {
    cikis() {
      localStorage.removeItem("storedData");
      this.$router.push("/login");
    }
  },
  mounted() { 
    // Eğer mount işlemi sırasında bir işlem yapılması gerekiyorsa buraya ekleyebilirsiniz
  },computed:{

    kullaniciGetir() {
      return localStorage.getItem("email") || ""
    },
  }
}
</script>