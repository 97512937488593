var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"add_ticket","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('form',[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Assign Staff")]),_c('vue-select',{attrs:{"options":_vm.addticketassignstaff}})],1)]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Client")]),_c('vue-select',{attrs:{"options":_vm.addticketclient}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Priority")]),_c('vue-select',{attrs:{"options":_vm.addticketpriority}})],1)]),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])])])]),_c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"edit_ticket","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(7),_c('div',{staticClass:"modal-body"},[_c('form',[_vm._m(8),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Assign Staff")]),_c('vue-select',{attrs:{"options":_vm.editticketassignstaff}})],1)]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Client")]),_c('vue-select',{attrs:{"options":_vm.editticketclient}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Priority")]),_c('vue-select',{attrs:{"options":_vm.editticketpriority}})],1)]),_vm._m(9)]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)])])])])]),_vm._m(14)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Add Ticket")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ticket Subject")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ticket Id")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("CC")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Assign")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ticket Assignee")]),_c('div',{staticClass:"project-members"},[_c('a',{staticClass:"avatar",attrs:{"title":"John Smith","data-placement":"top","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-02.jpg"),"alt":""}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Add Followers")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ticket Followers")]),_c('div',{staticClass:"project-members"},[_c('a',{staticClass:"avatar",attrs:{"title":"Richard Miles","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-09.jpg"),"alt":""}})]),_c('a',{staticClass:"avatar",attrs:{"title":"John Smith","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-10.jpg"),"alt":""}})]),_c('a',{staticClass:"avatar",attrs:{"title":"Mike Litorus","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-05.jpg"),"alt":""}})]),_c('a',{staticClass:"avatar",attrs:{"title":"Wilmer Deluna","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-11.jpg"),"alt":""}})]),_c('span',{staticClass:"all-team"},[_vm._v("+2")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Description")]),_c('textarea',{staticClass:"form-control"})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Upload Files")]),_c('input',{staticClass:"form-control",attrs:{"type":"file"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-section"},[_c('button',{staticClass:"btn btn-primary submit-btn"},[_vm._v("Submit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Edit Ticket")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ticket Subject")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"Laptop Issue"}})])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ticket Id")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":"","value":"TKT-0001"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("CC")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Assign")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ticket Assignee")]),_c('div',{staticClass:"project-members"},[_c('a',{staticClass:"avatar",attrs:{"title":"John Smith","data-placement":"top","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-02.jpg"),"alt":""}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Add Followers")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ticket Followers")]),_c('div',{staticClass:"project-members"},[_c('a',{staticClass:"avatar",attrs:{"title":"Richard Miles","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-09.jpg"),"alt":""}})]),_c('a',{staticClass:"avatar",attrs:{"title":"John Smith","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-10.jpg"),"alt":""}})]),_c('a',{staticClass:"avatar",attrs:{"title":"Mike Litorus","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-05.jpg"),"alt":""}})]),_c('a',{staticClass:"avatar",attrs:{"title":"Wilmer Deluna","data-bs-toggle":"tooltip","href":"#"}},[_c('img',{attrs:{"src":require("../../../../assets/img/profiles/avatar-11.jpg"),"alt":""}})]),_c('span',{staticClass:"all-team"},[_vm._v("+2")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Description")]),_c('textarea',{staticClass:"form-control"})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Upload Files")]),_c('input',{staticClass:"form-control",attrs:{"type":"file"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-section"},[_c('button',{staticClass:"btn btn-primary submit-btn"},[_vm._v("Save")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal custom-modal fade",attrs:{"id":"delete_ticket","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-header"},[_c('h3',[_vm._v("Delete Ticket")]),_c('p',[_vm._v("Are you sure want to delete?")])]),_c('div',{staticClass:"modal-btn delete-action"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a',{staticClass:"btn btn-primary continue-btn",attrs:{"href":"javascript:void(0);"}},[_vm._v("Delete")])]),_c('div',{staticClass:"col-6"},[_c('a',{staticClass:"btn btn-primary cancel-btn",attrs:{"href":"javascript:void(0);","data-bs-dismiss":"modal"}},[_vm._v("Cancel")])])])])])])])])
}]

export { render, staticRenderFns }