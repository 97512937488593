<template>
  <div class="formvalidation">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
      <div class="content container-fluid">
        
      <headerformvalidation />
          
      <formvalidationcontent />
        
        </div>      
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   
  export default {
    components: {
   
    },
    mounted() {


    },
    name: 'formvalidation'
  }
</script>