// Tooltip.vue
<template>
  <div class="tooltip-container">
    <slot></slot>
    <div class="tooltip" :class="{ active: isActive }">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    showTooltip() {
      this.isActive = true
    },
    hideTooltip() {
      this.isActive = false
    }
  },
  mounted() {
    const trigger = this.$el.querySelector(':first-child')
    trigger.addEventListener('mouseenter', this.showTooltip)
    trigger.addEventListener('mouseleave', this.hideTooltip)
  },
  beforeDestroy() {
    const trigger = this.$el.querySelector(':first-child')
    trigger.removeEventListener('mouseenter', this.showTooltip)
    trigger.removeEventListener('mouseleave', this.hideTooltip)
  }
}
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #333;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.tooltip.active {
  opacity: 1;
  visibility: visible;
}
</style>