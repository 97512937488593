<template>
  <!-- Add Employee Modal -->
  <div>
    <div id="add_employee" class="modal custom-modal fade" role="dialog">

      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Personel</h5>


            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">

              <div class="row">

                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Picture<span class="text-danger">*</span></label>
                    <input name="picture" v-model="picture" type="hidden">
                    <img :src="employee.picture" height="50" width="50" alt="" class="circle">
                    <input type="file" class="form-control" @change="previewImage" accept="image/*">
                  </div>
                </div> -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                    <div class="col-sm-3">
                      
                      <!-- <label class="col-form-label">Picture <span class="text-danger">*</span></label> &nbsp; -->
                    <input name="picture" v-model="employee.picture" type="hidden">
                    <img   height="80" width="80" alt="" class="circle"  src="../../../../assets/img/profiles/user.jpg" >
                    
                    </div>
                    <div class="col-sm-9">
                    <br/>
                    <input width="50" type="file" class="form-control" @change="previewImage" accept="image/*">
                    </div>
                   
                   </div>
                  </div>
                </div>
                <div class="col-sm-6 ">
                                        <div class="form-group">
                                            <label  class="col-form-label">Country</label>
                                            <Select2 :options="countrysetting" v-model="GetComp.Country" placeholder="Germany"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label  class="col-form-label">State/Province</label>
                                            <Select2 :options="statesetting" v-model="GetComp.StateProvince" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label  class="col-form-label">Address</label>
                                            <input class="form-control" v-model="employee.address" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 ">
                                        <div class="form-group">
                                            <label  class="col-form-label">Postal Code</label>
                                            <input class="form-control" v-model="GetComp.PostalCode" type="text">
                                        </div>
                                    </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">First Name <span class="text-danger">*</span></label>
                    <input class="form-control" v-model="employee.FirstName" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" v-model="employee.LastName" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Username <span class="text-danger">*</span></label>
                    <input class="form-control" v-model="employee.Username" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Email <span class="text-danger">*</span></label>
                    <input class="form-control" v-model="employee.Email" type="email">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input class="form-control" v-model="employee.Password" type="password">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Birth Date <span class="text-danger">*</span></label>

                    <input type="date" class="form-control" v-model="employee.date" valueType="format" format='DD-MM-YYYY' />

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input class="form-control" type="password">
                  </div>
                </div>
                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Personel ID <span class="text-danger">*</span></label>
                    <input type="text" v-model="employee.EmploeeID" class="form-control">
                  </div>
                </div> -->
                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Birth Date <span class="text-danger">*</span></label>

                    <date-picker valueType="format" format='DD-MM-YYYY' v-model="employee.date" :editable="true"
                      :clearable="false" />

                  </div>
                </div> -->
               
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input class="form-control" v-model="employee.Phone" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Way of Working</label>
                    <Select2 v-model="employee.wayOfWorking" :options="wayOfWorking" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Working Time (weekly)</label>

                    <input class="form-control" v-model="employee.workingTime" type="text">
                    <!-- <Select2 name="wayOfWorking" :options="wayOfWorking" /> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Department <span class="text-danger">*</span></label>
                    <Select2 v-model="employee.Department" :options="GetDepartment.map(p => p.DepartmentName)" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Working Fee (hourly)</label>
                    <input class="form-control" name="workingFee" v-model="employee.workingFee" type="text">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Gender <span class="text-danger">*</span></label>
                    <Select2 name="Gender" :options="gender" />
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Employee Modal -->

    <!-- Edit Employee Modal -->
    <div id="edit_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Employee</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


            <form @submit.prevent="EditDatas">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                    <div class="col-sm-3">
                      <!-- <label class="col-form-label">Picture <span class="text-danger">*</span></label> &nbsp; -->
                    <input name="picture" v-model="employee.picture" type="hidden">
                    <img :src="employee.picture" height="80" width="80" alt="" class="circle">
                    </div>
                    <div class="col-sm-9">
                    <br/>
                    <input width="50" type="file" class="form-control" @change="previewImage" accept="image/*">
                    </div>
                   
                   </div>
                  </div>
                </div>
                <div class="col-sm-6 ">
                                        <div class="form-group">
                                            <label  class="col-form-label">Country</label>
                                            <Select2 :options="countrysetting" v-model="GetComp.Country" placeholder="Germany"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label  class="col-form-label">State/Province</label>
                                            <Select2 :options="statesetting" v-model="GetComp.StateProvince" />
                                        </div>
                                    </div>
                
                  <!-- <div class="form-group">
                    <label class="col-form-label">Address <span class="text-danger">*</span></label>
                    <textarea name="address" id="" v-model="employee.address" class="form-control" cols="10"
                      rows="1"></textarea>

                  </div> -->
                
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label  class="col-form-label">Address</label>
                                            <input class="form-control" v-model="employee.address" type="text">
                                        </div>
                                    </div>
                                   
                                  
                                    <div class="col-sm-6 ">
                                        <div class="form-group">
                                            <label  class="col-form-label">Postal Code</label>
                                            <input class="form-control" v-model="GetComp.PostalCode" type="text">
                                        </div>
                                    </div>
                               
               
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">First Name <span class="text-danger">*</span></label>
                    <input class="form-control" name="FirstName" v-model="employee.FirstName" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" name="LastName" v-model="employee.LastName" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Username <span class="text-danger">*</span></label>
                    <input class="form-control" name="Username" v-model="employee.Username" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Email <span class="text-danger">*</span></label>
                    <input class="form-control" name="Email" v-model="employee.Email" type="email">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input class="form-control" name="Password" v-model="employee.Password" type="password">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Birth Date <span class="text-danger">*</span></label>

                    <input type="date" class="form-control" v-model="employee.date" valueType="format" format='DD-MM-YYYY' />

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input class="form-control" type="password">
                  </div>
                </div>

             
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input class="form-control" name="Phone" v-model="employee.Phone" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Way of Working</label>

                    <select class="form-control" name="wayOfWorking">
                      <option v-for="(item, index) in wayOfWorking" v-bind:key="index"
                        :selected="item == employee.wayOfWorking">
                        {{ item }}
                      </option>
                    </select>
                    <!-- <Select2 name="wayOfWorking" :options="wayOfWorking" /> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Working Time (weekly)</label>

                    <input class="form-control" name="workingTime" v-model="employee.workingTime" type="text">
                    <!-- <Select2 name="wayOfWorking" :options="wayOfWorking" /> -->
                  </div>
                </div>
             
                <div class="col-sm-6">
                  <div class="form-group">
                    <label  class="col-form-label">Department <span class="text-danger">*</span></label>
                    
                    <Select2 name="Department" v-model="employee.Department"
                      :options="GetDepartment.map(p => p.DepartmentName)" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Working Fee (hourly)</label>
                    <input class="form-control" name="workingFee" v-model="employee.workingFee" type="text">
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label  class="col-form-label">Gender <span class="text-danger">*</span></label>
                    <Select2 name="Gender" v-model="employee.Gender" :options="gender" />
                  </div>
                </div>
              </div>






              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Employee Modal -->

    <!-- Delete Employee Modal -->
    <div class="modal custom-modal fade" id="delete_employee" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Employee</h3>
              <p>Are you sure want to delete?</p>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a @click="DeleteDta" class="btn btn-primary continue-btn">Delete</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Employee Modal -->
</template>
<style scoped="">
img.preview {
  width: 200px;
}
</style>
<script>
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";

//import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
import { mapActions, mapGetters } from 'vuex'
const currentDate = new Date()
const currentDate1 = new Date()
export default {
  props: ["userdata"],
  components: {
   // DatePicker,
    Select2
  },
  data() {
    return {
      countrysetting: ["Germany"],
      statesetting: ["Baden-Württemberg",
"Bavaria",
"Berlin",
"Brandenburg",
"Bremen",
"Hamburg",
"Hesse",
"Mecklenburg-Vorpommern",
"Lower Saxony",
"North Rhine-Westphalia",
"Rhineland-Palatinate",
"Saarland",
"Saxony-Anhalt",
"Saxony",
"Schleswig-Holstein",
"Thuringia"
],
      imageData: null,
      picture: null,
      uploadValue: 0,



      compid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
      wayOfWorking: ["Full Time", "Part Time", "Minijop"],
      gender: ["Male", "Female"],
      addempcompany: ["Global Technologies", "Delta Infotech", "International Software Inc"],
      addempdepartment: ["Select Department", "Web Development", "IT Management", "Marketing"],
      addempdesignation: ["Select Designation", "Web Designer", "Web Developer", "Android Developer"],
      editempdepartment: ["Select Department", "Web Development", "IT Management", "Marketing"],
      editempdesignation: ["Select Designation", "Web Designer", "Web Developer", "Android Developer"],
      editempcompany: ["Global Technologies", "Delta Infotech", "International Software Inc"],
      startdate: currentDate,
      enddate: currentDate1,

      employee: {
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        EmploeeID: "",
        date: new Date(),
        Phone: "",
        picture: "",
        wayOfWorking: "",
        workingTime: "",
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        Department: "",
        workingFee: "",
      },

    }
  },


  methods: {


    ...mapActions(["AddData", "EditData", "DeleteData", "sorgu", "resimYukle"]),



    async previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
      this.employee.picture = await this.resimYukle(this.imageData)
    },


    SendData() {
      //console.log({ db: "Employee", mt: "SetEmployee", employee: this.employee });

      this.AddData({ db: "Employee", veri: this.employee }).then(p => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Employee", guncelle: "SetEmployee", kosul: [{ query: ["CompanyUid", "==", uid] }] })

      })
    },
    EditDatas(e) {
      console.log("DÜZENLENEN DATA", e.target);
      const formData = new FormData(e.target);
      const formProps = Object.fromEntries(formData);
      console.log(formProps);
      this.EditData({ db: "Employee", data: this.employee, id: this.userdata.id }).then(t => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Employee", guncelle: "SetEmployee", kosul: [{ query: ["CompanyUid", "==", uid] }] })

      })
    },
    DeleteDta() {
      this.DeleteData({ db: "Employee", mt: "DeleteEmployee", data: this.userdata }).then(p => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Employee", guncelle: "SetEmployee", kosul: [{ query: ["CompanyUid", "==", uid] }] })

      })
    },
    FetchDesignation() {
      this.fetchdata({ db: "Department", mt: "SetDept" }).then(() => {
        this.fetchdata({ db: "Designation", mt: "SetDest" })
      })
    }
  },
  watch: {
    userdata(value) {

      //console.log("KULLANICI BİLGİLERİ", this.userdata)
      this.employee = value
    }
  },
  computed: {
    ...mapGetters(["GetDepartment", "GetDest","Getcompany"]),
    GetDepartman() {
      // let arr = []
      // this.GetDept.forEach(e => {
      //   arr.push(e.DepartmentName)
      // })
      // return [...new Set(arr)]

      return ""
      // return this.GetDept.map(p => p.DepartmentName)
    },
 
GetComp(){
return this.Getcompany
},
    GetwayOfWorking() {

      return this.userdata.wayOfWorking
    },
    GetDesignation() {
      let arr = []
      this.GetDest.forEach(e => {
        arr.push(e.DesignationName)
      })
      return [...new Set(arr)]
    }
  },
  mounted() {

    console.log("KULLANICI BİLGİLERİ", this.userdata)


    // this.employee.date = "05-07-2022"
    //   this.FetchDesignation()
    //   console.log("USERDATA", this.employee);
  }
}
</script>
