<!-- <template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <gantt-elastic  
 :tasks="GetData" :options="options">
          <gantt-header slot="header"></gantt-header>
        </gantt-elastic>
      </div>
    </div>
    <div
      id="add_employee"
      ref="add"
      class="modal custom-modal fade"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Task</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >User <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      v-model="tasks.user"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Task Name</label>
                    <input
                      class="form-control"
                      v-model="tasks.label"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Duration(ex:15) <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      v-model="tasks.duration"
                      type="number"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Joining Date <span class="text-danger">*</span></label
                    >

                    <date-picker
                      v-model="tasks.start"
                      valueType="format"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Type</label>
                    <Select2 v-model="tasks.type" :options="tasklist" />
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
     
     
<div class="modal fade" id="change_task" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Lütfen Yapmak İstediğiniz İşlemi seçin</h5>
        <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#edit_task" @click="GeteditData">Düzenle
</button>

          </div>
          <div class="col">
            <button  type="button" class="btn btn-danger" @click="DelData">Sil</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

     <div
      id="edit_task"
      class="modal custom-modal fade"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Task</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="EditDatas">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >User <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      v-model=" editable_data.user"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Task Name</label>
                    <input
                      class="form-control"
                      v-model=" editable_data.label"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Duration(ex:15) <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      v-model=" editable_data.duration"
                      type="number"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Joining Date <span class="text-danger">*</span></label
                    >

                    <date-picker
                      v-model=" editable_data.start"
                      valueType="format"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Type</label>
                    <Select2  v-model=" editable_data.type" :options="tasklist" />
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import util from "../../../../assets/utils/util";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Select2 from "v-select2-component";
import GanttElastic from "gantt-elastic";
import GanttHeader from "gantt-elastic-header";
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
let  editable_data_id=""
export default {
  data() {
    return {
      tasklist: ["task", "milestone", "project"],
      editable_data:{   
        label: "",
        user: "",
        start: "",
        duration: 0,
        type: "",},
      tasks: {
        CompanyUid:JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        label: "",
        user: "",
        start: "",
        duration: 0,
        type: "",
      },
  
      options: {
        maxRows: 100,
        maxHeight: 300,
        title: {
          label: "Your project title as html (link or whatever...)",
          html: false,
        },
        row: {
          height: 24,
        },
        calendar: {
          hour: {
            display: true,
          },
        },
        chart: {
          progress: {
            bar: false,
          },
          expander: {
            display: true,
          },
        },
        taskList: {
          expander: {
            straight: false,
          },
          columns: [
        
            {
              id: 2,
              label: "Client",
              value: "label",
              width: 200,
              expander: true,
              html: true,
              events: {
                click({ data, column }) {
                  const el = document.getElementById("change_task");
                  el.classList.add("show");
                  el.style.display = "block";
                  el.ariaModal = true;
                  el.setAttribute("role", "dialog");
                  const close = document.getElementById("close");
                  close.addEventListener("click", () => {
                    el.classList.remove("show");
                    el.style.display = "none";
                  });
                  editable_data_id=data.id
                  console.log(data);
                  // alert('description clicked!\n' + data.label);
                },
              },
            },
            {
              id: 3,
              label: "Employee",
              value: "user",
              width: 130,
              html: true,
            },
            {
              id: 3,
              label: "Start",
              value: (task) => dayjs(task.start).format("YYYY-MM-DD"),
              width: 78,
            },
            {
              id: 4,
              label: "Type",
              value: "type",
              width: 68,
            },
            {
              id: 5,
              label: "%",
              value: "progress",
              width: 35,
              style: {
                "task-list-header-label": {
                  "text-align": "center",
                  width: "100%",
                },
                "task-list-item-value-container": {
                  "text-align": "center",
                  width: "100%",
                },
              },
            },
          ],
        },
      },
    };
  },
  components: {
    GanttElastic,
    GanttHeader,
    DatePicker,
    Select2,
  },
  methods: {
    ...mapActions(["fetchdata", "AddData","EditData","DeleteData"]),
    fetch() {
      this.fetchdata({ db: "DailyScheduling", mt: "SetSch" });
    },
    SendData() {
      this.tasks.duration = this.tasks.duration * 86400000;
      console.log(this.tasks);
      this.AddData({
        db: "DailyScheduling",
        mt: "SetSch",
        employee: this.tasks,
      });
    },
    tasksUpdate(){
      console.log("hoo");
    },
    GeteditData(){
let fin=this.GetData.find(el=>{
  return el.id==editable_data_id
})
fin.duration=fin.duration/86400000;
this.editable_data=fin
    },
    EditDatas(){
  console.log(this.editable_data);
  const data={}
data.label=this.editable_data.label
data.user=this.editable_data.user
data.start=this.editable_data.start
data.duration=this.editable_data.duration*86400000
data.type=this.editable_data.type
this.EditData({ db: "DailyScheduling", mt: "ChangeSch", id: this.editable_data.id, data:data})
    },
    DelData(){

      this.DeleteData({ db: "DailyScheduling", mt: "DeleteSch",data:{id:editable_data_id}})
    }
  },
  computed: {
    ...mapGetters(["GetSch"]),
    GetData() {
      return this.GetSch;
    },
  },
  mounted() {
    if (this.GetData.length == 0) {
      this.fetch();
    }

    util.datatable("#scheduletable");
  },
};
</script>
<style >
.gantt-elastic__chart-row-bar-polygon.gantt-elastic__chart-row-milestone-polygon{
  fill: #f7a264 !important;
  stroke:#f7a264 !important;
}
.gantt-elastic__chart-row-bar-polygon.gantt-elastic__chart-row-project-polygon{
  fill: rgb(58, 215, 141)!important;
  stroke:rgb(58, 215, 141)!important;
}
</style> -->

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped custom-table datatable" id="scheduletable">
          <thead>
            <tr>
              <th>Scheduled Shift</th>
              <th>Fri 21</th>
              <th>Sat 22</th>
              <th>Sun 23</th>
              <th>Mon 24</th>
              <th>Tue 25</th>
              <th>Wed 26</th>
              <th>Thu 27</th>
              <th>Fri 28</th>
              <th>Sat 29</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in GetSch" :key="item.id">
              <td hidden>{{index+1}}</td>

              <td>
                <h2 class="table-avatar">
                  <router-link to="/profile" class="avatar"><img alt=""
                      src="../../../../assets/img/profiles/avatar-02.jpg" /></router-link>
                  <router-link to="/profile">Personel {{item.Employee}} <span>Web Designer</span></router-link>
                </h2>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <h2>
                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule"
                      style="border: 2px dashed #1eb53a">
                      <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                      <span class="userrole-info">Web Designer - SMARTHR</span>
                    </a>
                  </h2>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <h2>
                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule"
                      style="border: 2px dashed #1eb53a">
                      <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                      <span class="userrole-info">Web Designer - SMARTHR</span>
                    </a>
                  </h2>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
            </tr>


          </tbody>
        </table>
      </div>
    </div>



    <div id="edit_schedule" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Schedule</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Client <span class="text-danger">*</span></label>
                    <Select2 name="clientslistData" v-model="clients" :options="getClientData"
                      placeholder="Please select company" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Employee Name <span class="text-danger">*</span></label>
                    <Select2 name="employeelistData" v-model="employees" :options="getEmployeeData"
                      placeholder="Please select employee" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <date-picker v-model="startdate" class="picker" placeholder="Please select start date"
                        :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Shifts <span class="text-danger">*</span></label>
                    <Select2 name="shiftlistData" v-model="shifts" :options="getClientData"
                      placeholder="Please select shift" />
                  </div>
                </div>
                <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Min Start Time <span class="text-danger">*</span></label>
<input class="form-control" type="text" value="06:11 am">
</div>
</div> -->
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Start Time <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="06:30 am">
                  </div>
                </div>
                <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Max Start Time <span class="text-danger">*</span></label>
<input class="form-control" type="text" value="08:12 am">
</div>
</div> -->
                <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Min End Time <span class="text-danger">*</span></label>
<input class="form-control" type="text" value="09:12 pm">
</div>
</div> -->
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">End Time <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="09:30 pm">
                  </div>
                </div>
                <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Max End Time <span class="text-danger">*</span></label>
<input class="form-control" type="text" value="09:45 pm">
</div>
</div> -->
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Break Time <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="45">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="custom-control form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck1">
                    <label class="form-check-label" for="customCheck1">Recurring Shift</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Repeat Every</label>
                    <select class="select">
                      <option value="">1 </option>
                      <option value="1">2</option>
                      <option value="2">3</option>
                      <option value="3">4</option>
                      <option selected value="4">5</option>
                      <option value="3">6</option>
                    </select>
                    <label class="col-form-label">Week(s)</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group wday-box">
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="monday"
                        class="days recurring" checked="" onclick="return false;"><span
                        class="checkmark">M</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="tuesday"
                        class="days recurring" checked="" onclick="return false;"><span
                        class="checkmark">T</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="wednesday"
                        class="days recurring" checked="" onclick="return false;"><span
                        class="checkmark">W</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="thursday"
                        class="days recurring" checked="" onclick="return false;"><span
                        class="checkmark">T</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="friday"
                        class="days recurring" checked="" onclick="return false;"><span
                        class="checkmark">F</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="saturday"
                        class="days recurring" onclick="return false;"><span class="checkmark">S</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="sunday"
                        class="days recurring" onclick="return false;"><span class="checkmark">S</span></label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">End On <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <date-picker v-model="startdate" class="picker" placeholder="Please select end date"
                        :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="custom-control form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck2">
                    <label class="form-check-label" for="customCheck2">Indefinite</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Accept Extra Hours </label>
                    <div class="form-check form-switch">
                      <input type="checkbox" class="form-check-input" id="customSwitch3" checked="">
                      <label class="form-check-label" for="customSwitch3"></label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Publish </label>
                    <div class="form-check form-switch">
                      <input type="checkbox" class="form-check-input" id="customSwitch4" checked="">
                      <label class="form-check-label" for="customSwitch4"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>
import util from "../../../../assets/utils/util";
import Vue from "vue";
import DatePicker from 'vue2-datepicker';
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from "vuex"
export default {
  props: ["datas"],
  data() {
    return {
      scheduling: {},
      editscheduling: {},
    };
  },
  components: {
    DatePicker,
    Select2
  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "getSch", "fetchdata"]),




  },
  computed: {
    ...mapGetters(["GetSch"]),
    getData() {
      return this.GetSch;
    }
  },

  mounted() {
    util.datatable("#scheduletable");
  },
};
</Script>