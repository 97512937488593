<template>
  <table class="table table-striped custom-table mb-0 datatable" id="departmenttable">
    <thead>
      <tr>
        <th style="width: 30px">#</th>
        <th>Department Name</th>
        <th class="text-end">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in GetDepartment" :key="item.id">
        <td>{{ index + 1 }}</td>
        <td>{{ item.DepartmentName }}</td>
        <td class="text-end">
          <div class="dropdown dropdown-action">
            <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                class="material-icons">more_vert</i></a>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_department"
                @click="edit(item)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_department"
                @click="delDepartment(item)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>

import util from "../../../../assets/utils/util";

import { mapGetters, mapActions } from 'vuex';

import Vue from "vue";
export default {
  data() {
    return {
      department: [],
    };
  },
  methods: {

    edit(e) {
      console.log(e);
      this.$emit("EditData", e);  //Index.vue ya veri gönderdik
    },
    delDepartment(d) {
      this.$emit("DeleteData", d);
    }
  },
  computed: {
    ...mapGetters(["GetDepartment"]),
    GetDepartman() {
      return this.GetDept
    }
  },
  components: {},
  mounted() {
    // util.datatable('#departmenttable')
    if (this.GetDepartman.length == 0) { this.fetchdataa(); }
  },
};
</script>
