<template>
  <div class="subscribedcompanies">
    <div class="main-wrapper">
     <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
          
          <!-- Page Header -->
          <div class="page-header"> 
            <div class="row">
              <div class="col">
                <h3 class="page-title">Subscribed Companies</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Subscriptions</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <companiesfilter />

          <companiestable />
          
          <companiesmodal />

        </div>
        <!-- /Page Content -->
        
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   
  export default {
    components: {
   
    },
    mounted() {
    },
    name: 'subscribedcompanies'
  }
</script>