var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-table"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-nowrap custom-table mb-0"},[_vm._m(1),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_c('router-link',{attrs:{"to":"/job-details"}},[_vm._v("Web Developer")])],1),_c('td',[_vm._v("Development")]),_vm._m(2),_vm._m(3)]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_c('router-link',{attrs:{"to":"/job-details"}},[_vm._v("Web Designer")])],1),_c('td',[_vm._v("Designing")]),_vm._m(4),_vm._m(5)]),_c('tr',[_c('td',[_vm._v("3")]),_c('td',[_c('router-link',{attrs:{"to":"/job-details"}},[_vm._v("Android Developer")])],1),_c('td',[_vm._v("Android")]),_vm._m(6),_vm._m(7)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title mb-0"},[_vm._v("Offered Jobs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Job Title")]),_c('th',[_vm._v("Department")]),_c('th',{staticClass:"text-center"},[_vm._v("Job Type")]),_c('th',{staticClass:"text-center"},[_vm._v("Actions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" Full Time ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-sm btn-info download-offer",attrs:{"href":"#"}},[_c('span',[_c('i',{staticClass:"fa fa-download me-1"}),_vm._v(" Download Offer")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" Part Time ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-sm btn-info download-offer",attrs:{"href":"#"}},[_c('span',[_c('i',{staticClass:"fa fa-download me-1"}),_vm._v(" Download Offer")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" Internship ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-sm btn-info download-offer",attrs:{"href":"#"}},[_c('span',[_c('i',{staticClass:"fa fa-download me-1"}),_vm._v(" Download Offer")])])])
}]

export { render, staticRenderFns }