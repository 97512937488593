<template>
  <div class="clientprofile">

    burası
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">

          <clientprofileheader  />

          <clientheadercontent :data="client" />

          <clientprofiletab />

          <clientprofiletabcontent  />

        </div>
        <!-- /Page Content -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data(){ 
return{
   data:this.$route.params.data,
    client:null,
}
  },
  methods:{
    ...mapActions(["clientGetir"]),
  },
  components: {

  },
  mounted() {
this.clientGetir({id:this.data}).then((a)=>{
  console.log("VERİ",a.data());
  this.client={id:a.id, ...a.data()}
})
  },
  name: 'clientprofile'
}
</script>