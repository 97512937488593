var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"action":"#"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Default Select")]),_c('div',{staticClass:"col-md-10"},[_c('vue-select',{attrs:{"options":_vm.defaultselect}})],1)]),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])]),_vm._m(11)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("Basic Inputs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Text Input")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Password")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"password"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Disabled Input")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":"disabled"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Readonly Input")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"readonly","readonly":"readonly"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Placeholder")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Placeholder"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("File Input")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"file"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Radio")]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"radio"},[_c('label',[_c('input',{attrs:{"type":"radio","name":"radio"}}),_vm._v(" Option 1 ")])]),_c('div',{staticClass:"radio"},[_c('label',[_c('input',{attrs:{"type":"radio","name":"radio"}}),_vm._v(" Option 2 ")])]),_c('div',{staticClass:"radio"},[_c('label',[_c('input',{attrs:{"type":"radio","name":"radio"}}),_vm._v(" Option 3 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Checkbox")]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{attrs:{"type":"checkbox","name":"checkbox"}}),_vm._v(" Option 1 ")])]),_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{attrs:{"type":"checkbox","name":"checkbox"}}),_vm._v(" Option 2 ")])]),_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{attrs:{"type":"checkbox","name":"checkbox"}}),_vm._v(" Option 3 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Textarea")]),_c('div',{staticClass:"col-md-10"},[_c('textarea',{staticClass:"form-control",attrs:{"rows":"5","cols":"5","placeholder":"Enter text here"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-0 row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Input Addons")]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}}),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v("Button")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("Input Sizes")])]),_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"action":"#"}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Large Input")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":".form-control-lg"}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Default Input")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":".form-control"}})])]),_c('div',{staticClass:"form-group mb-0 row"},[_c('label',{staticClass:"col-form-label col-md-2"},[_vm._v("Small Input")]),_c('div',{staticClass:"col-md-10"},[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"text","placeholder":".form-control-sm"}})])])])])])
}]

export { render, staticRenderFns }