<template>
  <div class="clientslist">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->

      <div class="page-wrapper">
        <!-- Page Content -->

        <div class="content container-fluid">
          <clientsheader />

          <clientsfilter />

          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table
                  class="table table-striped custom-table datatable"
                  id="clientlist"
                >
                  <thead>
                    <tr>
                    <th>Name</th>
                      <th>Job Title</th>
                      <th>Contact Person</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <!-- <th>Status</th>
                      <th class="text-end">Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in GetClientData" :key="item.id">
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"
                            ><img src="../../../assets/img/profiles/client.jpg" alt=""
                          /></router-link>
                          <router-link to="/client-profile">{{
                            item.ClientName
                          }}</router-link>
                        </h2>
                      </td>
                      <td>{{ item.JobTitle }}</td>
                      <td>{{ item.FirstName +" "+item.LastName }}</td>
                      <td>{{ item.Email }}</td>
                      <td>{{ item.Phone }}</td>
                      <!-- <td>
                        <div class="dropdown action-label">
                          <a
                            href="#"
                            class="
                              btn btn-white btn-sm btn-rounded
                              dropdown-toggle
                            "
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-dot-circle-o text-success"></i>
                            {{ item.status }}
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-success"></i>
                              Active</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-dot-circle-o text-danger"></i>
                              Inactive</a
                            >
                          </div>
                        </div>
                      </td> -->
                      <!-- <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#edit_client"
                              ><i class="fa fa-pencil m-r-5"></i> Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_client"
                              ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->

        <clientsmodal />
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import clientlist from "../../../assets/json/clientlist.json";
import util from "../../../assets/utils/util";
// eslint-disable-next-line

import { mapActions,mapGetters } from "vuex";
export default {
  name: "clientslist",
  data() {
    return {
      clientlist: clientlist,
    };
  },
  components: {},

  methods: {
    ...mapActions(["fetchdata"]),
    fetch() {
      this.fetchdata({ db: "Client", mt: "SetClient" });
    },

  },
  computed:{
    ...mapGetters(["Getclient"]),
GetClientData(){
return this.Getclient
}
  },
  mounted() {
if(this.GetClientData.length==0){
  this.fetch()
}
    util.datatable("#clientlist");
  },
};
</script>