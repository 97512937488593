<template>
  <!-- Add Designation Modal -->
  <div>
    <div id="add_designation" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Designation</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">
              <div class="form-group">
                <label>Designation Name <span class="text-danger">*</span></label>
                <input v-model="designation.DesignationName" class="form-control" type="text">
              </div>
              <div class="form-group">
                <label>Department <span class="text-danger">*</span></label>
                <Select2 v-model="designation.DepartmentID"
                  :options="$store.getters.GetDepartment.map(t=>t.DepartmentName)" />
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Designation Modal -->

    <!-- Edit Designation Modal -->
    <div id="edit_designation" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Designation</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="EditDat">
              <div class="form-group">
                <label>Designation Name <span class="text-danger">*</span></label>
                <input class="form-control" name="DesignationName" v-model="designation.DesignationName" type="text">
              </div>
              <div class="form-group">
                <label>Department <span class="text-danger">*</span></label>
                <Select2 name="DepartmentID" v-model="designation.DepartmentID"
                  :options="$store.getters.GetDepartment.map(t=>t.DepartmentName)" />
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Designation Modal -->

    <!-- Delete Designation Modal -->
    <div class="modal custom-modal fade" id="delete_designation" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Designation</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="DelDat">Delete</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Designation Modal -->
</template>
<script>
import Select2 from 'v-select2-component'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ["datas"],
  data() {
    return {
      designation: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        DepartmentID: "",
        DesignationName: ""
      }
    }
  },
  components: {
    Select2
  },

  watch: {
    datas(value) {

      this.designation = value
    }
  },
  methods: {
    ...mapActions(["sorgu", "AddData", "EditData", "DeleteData"]),

    SendData() {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      this.AddData({ db: "Designation", mt: "SetDesignation", veri: this.designation }).then(p => {
        $(".close").click();

        this.sorgu({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] })
      })
    },
    EditDat(e) {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;

      this.EditData({ db: "Designation", data: this.designation, id: this.designation.id })
      $(".close").click()
      this.sorgu({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] })

    },
    DelDat() {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      this.DeleteData({ db: "Designation", mt: "DeleteDest", data: this.datas })
      $(".close").click()
      this.sorgu({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] })

    }
  },
  computed: {
    ...mapGetters(["GetDesignation"]),
    // GetDepartman() {
    //   let arr = []
    //   this.GetDept.forEach(e => {
    //     arr.push(e.DepartmentName)
    //   })
    //   return [...new Set(arr)]
    // },
  },
  mounted() {

  }
}
</script>