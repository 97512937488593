var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('vue-select',{attrs:{"options":_vm.editinvoiceclient}})],1)]),_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('vue-select',{attrs:{"options":_vm.editinvoiceproject}})],1)]),_vm._m(2),_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tax")]),_c('vue-select',{attrs:{"options":_vm.editinvoicetax}})],1)]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_c('div',{staticClass:"cal-icon"},[_c('datepicker',{staticClass:"picker",attrs:{"editable":true,"clearable":false},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}})],1)])]),_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"form-group"},[_vm._m(6),_c('div',{staticClass:"cal-icon"},[_c('datepicker',{staticClass:"picker",attrs:{"editable":true,"clearable":false},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Client "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Project "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","value":"barrycuda@example.com"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Client Address")]),_c('textarea',{staticClass:"form-control",attrs:{"rows":"3"}},[_vm._v("5754 Airport Rd, Coosada, AL, 36020")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Billing Address")]),_c('textarea',{staticClass:"form-control",attrs:{"rows":"3"}},[_vm._v("5754 Airport Rd, Coosada, AL, 36020")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Invoice date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Due Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }