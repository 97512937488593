var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_vm._m(0),_c('h4',{staticClass:"page-title"},[_vm._v("PHP Email Settings")]),_vm._m(1),_c('h4',{staticClass:"page-title m-t-30"},[_vm._v("SMTP Email Settings")]),_c('div',{staticClass:"row"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("SMTP Security")]),_c('vue-select',{attrs:{"options":_vm.smtpsecurity}})],1)]),_vm._m(6)]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"mailoption","id":"phpmail","value":"option1"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"phpmail"}},[_vm._v("PHP Mail")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":"mailoption","id":"smtpmail","value":"option2"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"smtpmail"}},[_vm._v("SMTP")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email From Address")]),_c('input',{staticClass:"form-control",attrs:{"type":"email"}})])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Emails From Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("SMTP HOST")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("SMTP USER")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("SMTP PASSWORD")]),_c('input',{staticClass:"form-control",attrs:{"type":"password"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("SMTP PORT")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("SMTP Authentication Domain")]),_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-section"},[_c('button',{staticClass:"btn btn-primary submit-btn"},[_vm._v("Save & update")])])
}]

export { render, staticRenderFns }