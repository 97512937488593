<template>
<!-- Profile Modal -->
<div>
        <div id="profile_info" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Profile Information</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="EditDatas">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="profile-img-wrap edit-img">
                        <img class="inline-block" src="../../../assets/img/profiles/avatar-02.jpg" alt="user">
                        <div class="fileupload btn">
                          <span class="btn-text">edit</span>
                          <input class="upload" type="file">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>First Name</label>
                            <input type="text" name="FirstName"  :value="data.FirstName" class="form-control">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" name="LastName" :value="data.LastName" class="form-control" >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Birth Date</label>
                            <div class="cal-icon">
                              <div class="cal-icon">
                          <date-picker name="BirthDate" valueType="format" format='DD-MM-YYYY' :value="data.BirthDate"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                    </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Gender</label>
                            <Select2 :options="genderprofile" :value="data.Gender" name="Gender" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Address</label>
                        <input type="text" name="Address" class="form-control" :value="data.Address">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>State</label>
                        <input type="text" name="State" class="form-control" :value="data.State">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Country</label>
                        <input type="text" name="Country" class="form-control" :value="data.Country">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Pin Code</label>
                        <input type="text" name="PinCode" class="form-control" :value="data.PinCode" >
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Phone Number</label>
                        <input type="text" class="form-control"  name="Phone" :value="data.Phone">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Department <span class="text-danger">*</span></label>
                        <Select2 name="Department" :value="data.Department" :options="GetDepartman" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Designation <span class="text-danger">*</span></label>
                        <Select2 name="DesignationName" :value="data.DesignationName" :options="GetDesignation" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Reports To <span class="text-danger">*</span></label>
                        <Select2  :options="reports" />
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Profile Modal -->
        
        <!-- Personal Info Modal
        <div id="personal_info_modal" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Personal Information</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Passport No</label>
                        <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Passport Expiry Date</label>
                        <div class="cal-icon">
                          <date-picker valueType="format" format='DD-MM-YYYY' v-model="enddate"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                    </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Tel</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Nationality <span class="text-danger">*</span></label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Religion</label>
                        <div class="cal-icon">
                          <input class="form-control" type="text">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Marital status <span class="text-danger">*</span></label>
                        <Select2 :options="perosnalprofile" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Employment of spouse</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>No. of children </label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        <!-- /Personal Info Modal -->
        
        <!-- Family Info Modal -->
        <!-- <div id="family_info_modal" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title"> Family Informations</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-scroll">
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Family Member <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Name <span class="text-danger">*</span></label>
                              <input class="form-control" type="text">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Relationship <span class="text-danger">*</span></label>
                              <input class="form-control" type="text">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Date of birth <span class="text-danger">*</span></label>
                              <input class="form-control" type="text">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Phone <span class="text-danger">*</span></label>
                              <input class="form-control" type="text">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Education Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Name <span class="text-danger">*</span></label>
                              <input class="form-control" type="text">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Relationship <span class="text-danger">*</span></label>
                              <input class="form-control" type="text">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Date of birth <span class="text-danger">*</span></label>
                              <input class="form-control" type="text">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Phone <span class="text-danger">*</span></label>
                              <input class="form-control" type="text">
                            </div>
                          </div>
                        </div>
                        <div class="add-more">
                          <a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        <!-- /Family Info Modal -->
        
        <!-- Emergency Contact Modal -->
        <!-- <div id="emergency_contact_modal" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Personal Information</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="card">
                    <div class="card-body">
                      <h3 class="card-title">Primary Contact</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Relationship <span class="text-danger">*</span></label>
                            <input class="form-control" type="text">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Phone <span class="text-danger">*</span></label>
                            <input class="form-control" type="text">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Phone 2</label>
                            <input class="form-control" type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="card">
                    <div class="card-body">
                      <h3 class="card-title">Primary Contact</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Relationship <span class="text-danger">*</span></label>
                            <input class="form-control" type="text">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Phone <span class="text-danger">*</span></label>
                            <input class="form-control" type="text">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Phone 2</label>
                            <input class="form-control" type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        <!-- /Emergency Contact Modal -->
        
        <!-- Education Modal -->
        <!-- <div id="education_info" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title"> Education Informations</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-scroll">
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Education Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Oxford University" class="form-control floating">
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Computer Science" class="form-control floating">
                            
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon">
                            <date-picker valueType="format" format='DD-MM-YYYY' v-model="startdate1"  class="picker" 
                              :editable="true"
                              :clearable="false" />
                            </div>
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon">
                              <date-picker valueType="format" format='DD-MM-YYYY' v-model="enddate1"  class="picker" 
                              :editable="true"
                              :clearable="false" />
                              </div>
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="BE Computer Science" class="form-control floating">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Grade A" class="form-control floating">
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Education Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Oxford University" class="form-control floating">
                              <label class="focus-label">Institution</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Computer Science" class="form-control floating">
                              <label class="focus-label">Subject</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon">
                            <date-picker valueType="format" format='DD-MM-YYYY' v-model="startdate2"  class="picker" 
                              :editable="true"
                              :clearable="false" />
                            </div>
                           
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon">
                            <date-picker valueType="format" format='DD-MM-YYYY' v-model="enddate2"  class="picker" 
                               :editable="true"
                               :clearable="false" />
                               </div>
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="BE Computer Science" class="form-control floating">
                              <label class="focus-label">Degree</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Grade A" class="form-control floating">
                              <label class="focus-label">Grade</label>
                            </div>
                          </div>
                        </div>
                        <div class="add-more">
                          <a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        <!-- /Education Modal -->
        
        <!-- Experience Modal -->
        <!-- <div id="experience_info" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Experience Informations</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-scroll">
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Experience Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="Digital Devlopment Inc">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="United States">
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="Web Developer">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <div class="cal-icon">
                          <date-picker valueType="format" format='DD-MM-YYYY' v-model="startdate3"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                      </div>
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <div class="cal-icon">
                          <date-picker valueType="format" format='DD-MM-YYYY' v-model="enddate3"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                     </div>
                         
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Experience Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="Digital Devlopment Inc">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="United States">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="Web Developer">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <div class="cal-icon">
                          <date-picker valueType="format" format='DD-MM-YYYY' v-model="startdate4"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                      </div>
                            
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <div class="cal-icon">
                          <date-picker valueType="format" format='DD-MM-YYYY' v-model="enddate4"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                     </div>
                           
                            </div>
                          </div>
                        </div>
                        <div class="add-more">
                          <a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        </div>
        <!-- /Experience Modal -->
</template>
<script>
  
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
  import {mapActions,mapGetters} from 'vuex'
  const currentDate = new Date()
  const currentDate1 = new Date()
  export default {
    props:["data"],
    components:{
DatePicker,
Select2
    },
     data() {
    return {
      compid :JSON.parse(localStorage.getItem("storedData")).CompanyUid,
   genderprofile: ["Male", "Female"],
      departmenprofile: ["Select Department", "Web Development", "IT Management", "Marketing"],
      designation: ["Select Designation", "Web Designer", "Web Developer", "Android Developer"],
      reports: ["-", "Wilmer Deluna", "Lesley Grauer", "Jeffery Lalor"],
      perosnalprofile: ["-", "Single", "Married"],
  
employee:{
FirstName:"",
LastName:"",
Email:"",
Password:"",
EmploeeID:"",
JoiningDate:null,
Phone:"",
Company:"",
CompanyUid:JSON.parse(localStorage.getItem("storedData")).CompanyUid,
  Department:"",
  DesignationName:"",
},

    }
    },
    methods: {
      ...mapActions(["EditData","DeleteData","fetchdata"]),

    EditDatas(e){
      console.log(e.target);
  const formData = new FormData(e.target);
  const formProps = Object.fromEntries(formData);
  console.log(formProps);
  this.EditData({db:"Employee",mt:"ChangeEmployee",data:formProps,id:this.data.id})
    },
    DeleteDta(){
this.DeleteData({db:"Employee",mt:"DeleteEmployee",data:this.data})
    },
    FetchDesignation(){
 this.fetchdata({db:"Department",mt:"SetDept"}).then(()=>{
this.fetchdata({db:"Designation",mt:"SetDest"})
 })
    }
    },
    computed:{
         ...mapGetters(["GetDept","GetDest"]),
    GetDepartman(){
      let arr=[]
 this.GetDept.forEach(e=>{
  arr.push(e.DepartmentName)
 })
 return[ ...new Set(arr)]
    },
    GetDesignation(){
         let arr=[]
 this.GetDest.forEach(e=>{
  arr.push(e.DesignationName)
 })
 return[ ...new Set(arr)]
    }
    },
    mounted() {
      this.FetchDesignation()
      console.log(this.compid);
    }
  }
</script>