<template>
  <!-- Search Filter -->
  <div class="row filter-row">
    <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
      <div class="form-group form-focus">
        <input type="text" v-model="Personel" class="form-control floating">
        <label class="focus-label">Personel Name</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
      <div class="form-group form-focus select-focus">
        <Select2 v-model="LeaveType" :options="searchfilterleavetype" />
        <label class="focus-label">Leave Type</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
      <div class="form-group form-focus select-focus">
        <Select2 v-model="LeaveStatus" :options="searchfilterleavestatus" />
        <label class="focus-label">Leave Status</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
      <div class="form-group form-focus">
        <div class="cal-icon">
          <date-picker v-model="startdate" class="picker1" :editable="true" :clearable="false" />
        </div>

      </div>
    </div>
    <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
      <div class="form-group form-focus">
        <div class="cal-icon">
          <date-picker v-model="startdate1" class="picker1" :editable="true" :clearable="false" />
        </div>

      </div>
    </div>
    <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
      <a href="#" @click="ara()" class="btn btn-success w-100"> Search </a>
    </div>
  </div>
  <!-- /Search Filter -->
</template>
<script>

import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
import { mapActions } from 'vuex';

const currentDate = new Date()
const currentDate1 = new Date()
export default {

  data() {
    return {
      searchfilterleavetype: ["All", "Casual Leave 12 Days", "Medical Leave", "Loss of Pay"],
      searchfilterleavestatus: ["All", "Pending", "Planned", "UnPlanned", "Accepted", "Declined"],
      startdate: null,
      startdate1: null,
      LeaveType: "",
      LeaveStatus: "",
      Personel: ""

    }
  },
  components: {
    DatePicker,
    Select2
  },
  methods: {
    ...mapActions(["leavesGetir", "sorgu"]),
    ara() {

      var sorgular = []
      if (this.LeaveType != "All" && this.LeaveType != "") sorgular.push({ query: ["LeaveType", "==", this.LeaveType] })
      if (this.LeaveStatus != "All" && this.LeaveStatus != "") sorgular.push({ query: ["status", "==", this.LeaveStatus] })
      if (this.Personel != "") sorgular.push({ query: ["Personel", ">=", this.Personel] })
      if (this.startdate != null) sorgular.push({ query: ["startdate", ">", this.startdate] })
      if (this.startdate1 != null) sorgular.push({ query: ["startdate", "<", this.startdate1] })

      this.sorgu({ tablo: "Leaves", kosul: sorgular, guncelle: "SetLeaves" })

    }
  },
  mounted() {
  }
}
</Script>