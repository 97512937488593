<!-- <template>
  <div class="shiftscheduling">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
         Page Wrapper  
       <div class="page-wrapper">
      
        Page Content  
        <div class="content container-fluid">
          
          Page Header  
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">Daily Scheduling</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                                    <li class="breadcrumb-item"><router-link to="/employees">Employees</router-link></li>
                                    <li class="breadcrumb-item active">Shift Scheduling</li>
                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_employee"><i class="fa fa-plus"></i> Add Employee</a>

              </div>     
            </div>
          </div>
          
          <div class="row filter-row">
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <input type="text" class="form-control floating">
                <label class="focus-label">Employee</label>
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3"> 
              <div class="form-group form-focus select-focus">
              <Select2 :options="schedulingdepartment" />
                <label class="focus-label">Department</label>
              </div>
                        </div>
                        <div class="col-sm-6 col-md-2">  
              <div class="form-group form-focus focused">
                <div class="cal-icon">
                          <date-picker v-model="startdate"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                    </div>
                
              </div>
                        </div>
                        <div class="col-sm-6 col-md-2">  
              <div class="form-group form-focus focused">
                <div class="cal-icon">
                          <date-picker v-model="enddate"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                    </div>
                
              </div>
            </div>
            <div class="col-sm-6 col-md-2">  
              <a href="#" class="btn btn-success w-100"> Search </a>  
            </div>     
                    </div>
        

          <schedulingtable />

          
          
         </div>
       
        
      </div>
       
      

    </div>
  </div>
</template>
<script>
   
  
  const currentDate = new Date()
  const currentDate1 = new Date()
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
  export default {
     data() {
    return {
      schedulingdepartment: ["All Department", "Finance", "Finance and Management", "Hr & Finance", "ITech"],
      startdate: currentDate,
      enddate: currentDate1,
    }
    },
    components: {
   DatePicker,
Select2
    },
    mounted() {
    },
    name: 'shiftscheduling'
  }
</script> -->

<template>
  <div class="shiftscheduling">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">
          <div class="col-auto float-end ms-auto">
            <!-- <a href="shift-list" class="btn add-btn m-r-5">Shifts</a> -->
            <router-link to="/shift-list"><a class="btn add-btn m-r-5">Shifts</a></router-link>
            <a href="#" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_schedule"> Assign
              Shifts</a>
          </div>
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">Daily Scheduling</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/index">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/employees">Employees</router-link>
                  </li>
                  <li class="breadcrumb-item active">Shift Scheduling</li>
                </ul>
              </div>

            </div>
          </div>
          <!-- /Page Header -->
          <div id="add_schedule" class="modal custom-modal fade" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Add Schedule</h5>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label">Client <span class="text-danger">*</span></label>
                          <Select2 name="clientslistData" v-model="clients" :options="getClientData"
                            placeholder="Please select company" />

                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label">Job Title <span class="text-danger">*</span></label>
                          <Select2 name="clientslistData" v-model="clients" :options="getClientData"
                            placeholder="Please select company" />

                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label">Shifts Time <span class="text-danger">*</span></label>
                          <Select2 name="clientslistData" v-model="clients" :options="getClientData"
                            placeholder="Please select company" />

                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label">Employee Name <span class="text-danger">*</span></label>
                          <Select2 name="employeelistData" v-model="employees" :options="getEmployeeData"
                            placeholder="Please select employee" />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label">Start Date</label>
                          <div> <input type="date" v-model="startdate" name="startdate" class="picker"
                              placeholder="Please select start date" :editable="true" :clearable="false" /></div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label">End Date</label>
                          <div> <input type="date" v-model="enddate" name="enddate" class="picker"
                              placeholder="Please select start date" :editable="true" :clearable="false" /></div>
                        </div>
                      </div>
                      <!-- <div class="col-sm-6">
<div class="form-group">
<label class="col-form-label">Shifts <span class="text-danger">*</span></label>
  <Select2 name="shiftlistData" v-model="shifts" :options="getClientData" placeholder="Please select shift" />
</div>
</div> -->
                      <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Min Start Time <span class="text-danger">*</span></label>
<div class="input-group time timepicker">
<input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
</div>
</div>
 </div> -->
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="col-form-label">Start Time <span class="text-danger">*</span></label>
                          <div class="input-group time timepicker">
                            <input class="form-control"><span class="input-group-text"><i
                                class="fa fa-clock-o"></i></span>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Max Start Time <span class="text-danger">*</span></label>
<div class="input-group time timepicker">
<input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
</div>
</div>
</div> -->
                      <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Min End Time <span class="text-danger">*</span></label>
<div class="input-group time timepicker">
<input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
</div>
</div>
</div> -->
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="col-form-label">End Time <span class="text-danger">*</span></label>
                          <div class="input-group time timepicker">
                            <input class="form-control"><span class="input-group-text"><i
                                class="fa fa-clock-o"></i></span>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Max End Time <span class="text-danger">*</span></label>
<div class="input-group time timepicker">
<input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
</div>
</div>
</div> -->
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="col-form-label">Break Time <span class="text-danger">*</span></label>
                          <input class="form-control" type="text">
                        </div>
                      </div>
                      <!-- <div class="col-sm-6">
<div class="form-group">
<label class="col-form-label">Accept Extra Hours </label>
<div class="form-check form-switch">
 <input type="checkbox" class="form-check-input" id="customSwitch1" checked="">
<label class="form-check-label" for="customSwitch1"></label>
</div>
</div>
</div> -->
                      <!-- <div class="col-sm-6">
<div class="form-group">
<label class="col-form-label">Publish </label>
<div class="form-check form-switch">
<input type="checkbox" class="form-check-input" id="customSwitch2" checked="">
<label class="form-check-label" for="customSwitch2"></label>
</div>
</div>
</div> -->
                    </div>
                    <div class="submit-section">
                      <button class="btn btn-primary submit-btn">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Content Starts -->
          <!-- Search Filter -->
          <div class="row filter-row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus select-focus">
                <Select2 name="employeelistData" v-model="employees" :options="getEmployeeData"
                  placeholder="Please select employee" />

                <label class="focus-label">Employee</label>
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus select-focus">
                <Select2 name="clientslistData" v-model="clients" :options="getClientData"
                  placeholder="Please select company" />

                <label class="focus-label">Clients</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-2">
              <div class="form-group form-focus focused">
                <div class="cal-icon">
                  <date-picker v-model="startdate" class="picker" placeholder="Please select start date"
                    :editable="true" :clearable="false" />
                </div>

              </div>
            </div>
            <div class="col-sm-6 col-md-2">
              <div class="form-group form-focus focused">
                <div class="cal-icon">
                  <date-picker v-model="enddate" class="picker" placeholder="Please select end date" :editable="true"
                    :clearable="false" />
                </div>

              </div>
            </div>
            <div class="col-sm-6 col-md-2">
              <a href="#" class="btn btn-success w-100"> Search </a>
            </div>
          </div>
          <!-- Search Filter -->

          <schedulingtable />

          <!-- /Content End -->

        </div>
        <!-- /Page Content -->

      </div>
      <!-- /Page Wrapper -->


    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import { ref } from 'vue'
// const currentDate = ref(new Date())
// const currentDate1 = ref(new Date())
export default {
  data() {
    return {
      Vue,
      classList: {},

      startdate: "currentDate",
      enddate: "currentDate1",
      clients: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: ""
      },
      employees: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: ""
      },
    }
  },
  components: {
    Select2,
    DatePicker,
  },
  computed: {
    ...mapGetters(["Getclient", "GetEmployes", "GetDepartment"]),
    getClientData() {
      let arr = []
      this.Getclient.forEach(e => {
        arr.push(e.ClientName)
      })
      return [...new Set(arr)]
    },
    getEmployeeData() {
      let arr = []
      this.GetEmployes.forEach(e => {
        arr.push(e.FirstName + " " + e.LastName)
      })
      return [...new Set(arr)]
    },
    getDepartmentData() {
      let arr = []
      this.GetDepartment.forEach(e => {
        arr.push(e.DepartmentName)
      })
      return [...new Set(arr)]

    },



  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "getClients", "fetchdata", "getSch"]),

    setOption() {

      let addclientfilter = [];
      let addemployeefilter = [];

      this.getClientData.forEach((el) => {
        addclientfilter.push({ title: el.ClientName });

        this.getEmployeeData.forEach((el) => {
          addemployeefilter.push({ title: el.FirstName + el.LastName });
        })

      });
      console.log("MÜŞTERİLER", addclientfilter);
      console.log("PERSONELLER", addemployeefilter);
    }
  },



  mounted() {
    this.getSch()
  },
  name: 'shiftscheduling',
}
</Script>

<style>
.mx-input {
  height: 48px;
}
</style>