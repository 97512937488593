<template>

  <div class="policies">

    <div class="main-wrapper">

      <main-header></main-header>

      <sidebar></sidebar>
      
       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
        
        <header-policies />
          
        <policies-table />

        </div>
        <!-- /Page Content -->

        <modal-policies />
      
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   import HeaderPolicies from '@/views/pages/policies/headerpolicies.vue'
import PoliciesTable from '@/views/pages/policies/policiestable.vue'
import ModalPolicies from '@/views/pages/policies/modalpolicies.vue'
  export default {
    components: {
   HeaderPolicies,
ModalPolicies,
   PoliciesTable,
    },
    mounted() {

    },
    name: 'policies'
  }
</script>