<template>
  <!-- Content Starts -->
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped custom-table datatable" id="shifttable">
          <thead>
            <tr>
              <th>#</th>
              <th>Client Name</th>
              <th>Shift Name</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Break Time</th>
              <th>Recurring</th>
              <th>Recurring Week</th>
              <th>End On</th>
              <th>Note</th>
              <th class="text-center">Status</th>
              <th class="text-end no-sort">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in GetShiftTable" :key="item.id">
              <td>{{index+1}}</td>
              <td>{{item.Client}}</td>
              <td>{{item.ShiftName}}</td>
              <td>{{item.StartTime}}</td>
              <td>{{item.EndTime}}</td>
              <td>{{item.BreakTime}}</td>
              <td>{{item.RecurringShift}}</td>
              <td>{{item.RecurringShiftWeek}}</td>
              <td>{{item.ShiftEndOn}}</td>
              <td>{{item.Note}}</td>
              <td class="text-center">
                <div class="action-label">
                  <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                    <i class="fa fa-dot-circle-o text-success"></i> Active
                  </a>
                </div>
              </td>
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" @click="$emit('sendItem',item)" data-bs-toggle="modal"
                      data-bs-target="#edit_shift"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" @click="$emit('sendItem',item)" data-bs-toggle="modal"
                      data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

    <!-- //////////EDİT SHİFT /////////// -->
    <div id="edit_shift" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Shift</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editVeri">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Client Name <span class="text-danger">*</span></label>
                    <div class="input-group  ">
                      <input :value="datas.Client" class="form-control"><span class="input-group-text"><i
                          class="fa fa-clock-o"></i></span>
                      <!-- <Select2 name="clientslistData"   v-model="shiftTable.Client" :options="getClientData" placeholder="Please select company" />
    -->

                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Shift Name <span class="text-danger">*</span></label>
                    <div class="input-group time timepicker">
                      <input :value="datas.ShiftName" class="form-control"><span class="input-group-text"><i
                          class="fa fa-clock-o"></i></span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Start Time <span class="text-danger">*</span></label>
                    <div class="input-group time timepicker">
                      <input :value="datas.StartTime" class="form-control"><span class="input-group-text"><i
                          class="fa fa-clock-o"></i></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>End On <span class="text-danger">*</span></label>
                    <div> <input type="date" :value="datas.ShiftEndOn" class="picker"
                        placeholder="Please select start date" :editable="true" :clearable="false" /></div>
                  </div>
                </div>


                <div class="col-md-4">
                  <div class="form-group">
                    <label>Break Time (In Minutes) </label>
                    <input :value="datas.BreakTime" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>End Time <span class="text-danger">*</span></label>
                    <div class="input-group time timepicker">
                      <input :value="datas.EndTime" class="form-control"><span class="input-group-text"><i
                          class="fa fa-clock-o"></i></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group wday-box">
                    <label class="checkbox-inline"><input type="checkbox" value="monday" class="days recurring"
                        checked=""><span class="checkmark">M</span></label>
                    <label class="checkbox-inline"><input type="checkbox" value="tuesday" class="days recurring"
                        checked=""><span class="checkmark">T</span></label>
                    <label class="checkbox-inline"><input type="checkbox" value="wednesday" class="days recurring"
                        checked=""><span class="checkmark">W</span></label>
                    <label class="checkbox-inline"><input type="checkbox" value="thursday" class="days recurring"
                        checked=""><span class="checkmark">T</span></label>
                    <label class="checkbox-inline"><input type="checkbox" value="friday" class="days recurring"
                        checked=""><span class="checkmark">F</span></label>
                    <label class="checkbox-inline"><input type="checkbox" value="saturday" class="days recurring"><span
                        class="checkmark">S</span></label>
                    <label class="checkbox-inline"><input type="checkbox" value="sunday" class="days recurring"><span
                        class="checkmark">S</span></label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="col-form-label">Repeat Every</label> &nbsp;
                    <select class="select">
                      <option value="">1 </option>
                      <option value="1">2</option>
                      <option value="2">3</option>
                      <option value="3">4</option>
                      <option selected value="4">5</option>
                      <option value="3">6</option>
                    </select>
                    <label class="col-form-label">Week(s)</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="custom-control form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck1">
                    <label class="form-check-label" for="customCheck1">Recurring Shift</label>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="custom-control form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck2">
                    <label class="form-check-label" for="customCheck2">Indefinite</label>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Add Note </label>
                    <textarea :value="datas.Note" class="form-control" rows="2"></textarea>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn" >Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /////////////////////////////// -->

    <!-- /////DELETE POPUP///////// -->
    <div class="modal custom-modal fade" id="delete_employee" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Shift</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a @click.prevent="deleteVeri" class="btn btn-primary continue-btn">Delete</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ////////// -->


  </div>
  <!-- /Content End -->
</template>
<script>
import util from "../../../../assets/utils/util";
import Vue from "vue";
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from "vuex";
/* eslint-disable */
export default {
  props: ["datas"],
  data() {

    return {

      editshift: {
        Client:"",
        BreakTime:"",
        EndTime:"",
        Indefinite:true,
        Note:"",
        RecurringShift:true,
        RecurringShiftWeek:"",
        ShiftEndOn:"",
        ShiftName:"",
        StartTime:"",
        Status:true, 

      },
      shiftTable: {},
      startdate: "",

    }
  },
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters(["GetShiftTable"]),
    getData() {
      return this.GetShiftTable;
    },



  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "getShiftTable", "fetchdata"]),


    editShift(d) {
      this.$emit("EditData", d)
    },
    editShifts(h) {
      this.editshift = h;
    },
 

    editVeri(b) {
      const formData = new FormData(b.target);     
      const formProps = Object.fromEntries(formData);    
   
      this.EditData({ db: "ShiftTable", mt: "SetShiftTable", data: this.datas, id: this.datas.id }).then(() => {
        this.getShiftTable()
        $(".close").click()

      });

    },

    /////////SİLME İŞLEMİ ////////////////////////
    deleteVeri() {
      this.DeleteData({ db: "ShiftTable", mt: "DeleteShiftTable", data: this.datas }).then(() => {
        this.getShiftTable()
        $(".close").click()
      });
    },

  },

  mounted() {

  },
};
</script>
