<template>
  <div class="experiancelevel">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
        
        <experheader />
          
        <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <expertable />
              </div>
            </div>
        </div>
                </div>
        <!-- /Page Content -->
        
        <expermodal />
        
        </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   
  export default {
    components: {
   
    },
    mounted() {

    },
    name: 'experiancelevel'
  }
</script>