<template>
  <div>


    <!-- Add Training List Modal -->
    <div id="add_training" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New Training</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Training Type</label>
                    <Select2 name="traininglistData" v-model="trainingType" :options="getTypeData"
                      placeholder="Please select type" />

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Trainer</label>
                    <Select2 name="trainierlistData" v-model="trainier" :options="getTrainerData"
                      placeholder="Please select type" />

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Employees</label>
                    <Select2 name="employeelistData" v-model="employees" :options="getEmployeeData"
                      placeholder="Please select employee" />

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Training Cost <span class="text-danger">*</span></label>
                    <input class="form-control" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Start Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <date-picker v-model="startdate" class="picker" :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>End Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <date-picker v-model="enddate" class="picker" :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Description <span class="text-danger">*</span></label>
                    <textarea class="form-control" rows="4"></textarea>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <Select2 name="statusData" v-model="Status" :options="editstatus"
                      placeholder="Please select status" />
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Training List Modal -->

    <!-- Edit Training List Modal -->
    <div id="edit_training" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Training List</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Training Type</label>
                    <Select2 name="traininglistData" v-model="trainingType" :options="getTypeData"
                      placeholder="Please select type" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Trainer</label>
                    <Select2 name="trainierlistData" v-model="trainier" :options="getTrainerData"
                      placeholder="Please select type" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Employees</label>
                    <Select2 name="employeelistData" v-model="employees" :options="getEmployeeData"
                      placeholder="Please select employee" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Training Cost <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="$400">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Start Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <date-picker v-model="startdate1" class="picker" :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>End Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <date-picker v-model="enddate1" class="picker" :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Description <span class="text-danger">*</span></label>
                    <textarea class="form-control" rows="4">Lorem ipsum ismap</textarea>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <Select2 name="statusData" v-model="Status" :options="editstatus"
                      placeholder="Please select status" />
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Training List Modal -->

    <!-- Delete Training List Modal -->
    <div class="modal custom-modal fade" id="delete_training" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Training List</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" class="btn btn-primary continue-btn">Delete</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Training List Modal -->
</template>
<script>


const currentDate = new Date()
const currentDate1 = new Date()
const currentDate2 = new Date()
const currentDate3 = new Date()
import Vue from 'vue'
import DatePicker from 'vue2-datepicker';
import Select2 from "v-select2-component";


import { mapActions, mapGetters } from 'vuex';
export default {

  components: {
    DatePicker,
    Select2,
  },

  data() {
    return {
      Vue,



      trainingType: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: ""
      },
      addtraining: [],
      // addtrainer: ['Mike Litorus', 'John Doe'],
      // addemployees: ['Bernardo Galaviz', 'Jeffrey Warden'],
      // addstatus: ['Active', 'Inactive'],
      // edittraining: ["Node Training", "Swift Training"],
      // edittrainer: ['Mike Litorus', 'John Doe'],
      // editemployees: ['Bernardo Galaviz', 'Jeffrey Warden'],
      editstatus: ['Active', 'Inactive'],
      startdate: currentDate,
      enddate: currentDate1,
      startdate1: currentDate2,
      enddate1: currentDate3,
      employees: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: ""
      },
    }
  },
  computed: {
    ...mapGetters(["GetTrainingType", "GetEmployes", "GetTrainer"]),
    getTypeData() {
      //return this.GetTrainingType
      let arrb = []
      this.GetTrainingType.forEach(e => {
        arrb.push(e.Type)
      })
      console.log("DDD", this.GetTrainingType);
      return [...new Set(arrb)]
    },
    getEmployeeData() {
      let arr = [];
      this.GetEmployes.forEach(e => {
        arr.push(e.FirstName)
      })
      return [...new Set(arr)]
    },
    getTrainerData() {
      let arrs = []
      this.GetTrainer.forEach(e => {
        arrs.push(e.FirstName)
      })
      return [...new Set(arrs)]
    }

  },
  methods: {
    ...mapActions(["fetchdata"]),



  },

  mounted() {

  },

}
</script>