<template>
  <div class="settings">
    <div class="main-wrapper">
      <main-header></main-header>
      <settingsidebar></settingsidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
            
                <!-- Page Content -->
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-md-8 offset-md-2">
                        
                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="page-title">Company Settings</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
                            
                            <form @submit.prevent="edit">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Company Name <span class="text-danger">*</span></label>
                                            <input class="form-control" v-model="GetComp.CompanyName " type="text" >
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Contact Person</label>
                                            <input class="form-control" v-model="GetComp.ContactPerson" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Address</label>
                                            <input class="form-control" v-model="GetComp.Adress" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>Country</label>
                                            <Select2 :options="countrysetting" v-model="GetComp.Country" placeholder="Germany"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>City</label>
                                            <input class="form-control" v-model="GetComp.City" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>State/Province</label>
                                            <Select2 :options="statesetting" v-model="GetComp.StateProvince" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>Postal Code</label>
                                            <input class="form-control" v-model="GetComp.PostalCode" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input class="form-control" v-model="GetComp.Email" type="email">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Phone Number</label>
                                            <input class="form-control" v-model="GetComp.Phone" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Mobile Number</label>
                                            <input class="form-control" v-model="GetComp.MobileNumber" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Fax</label>
                                            <input class="form-control" v-model="GetComp.Fax" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Website Url</label>
                                            <input class="form-control" v-model="GetComp.WebsiteUrl" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="submit-section">
                                    <button class="btn btn-primary submit-btn">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- /Page Content -->
                
            </div>
            <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import Select2 from 'v-select2-component'
   import {mapActions, mapGetters}  from 'vuex'
  export default {
    data() {
    return {
      countrysetting: ["Germany"],
      statesetting: ["Baden-Württemberg",
"Bavaria",
"Berlin",
"Brandenburg",
"Bremen",
"Hamburg",
"Hesse",
"Mecklenburg-Vorpommern",
"Lower Saxony",
"North Rhine-Westphalia",
"Rhineland-Palatinate",
"Saarland",
"Saxony-Anhalt",
"Saxony",
"Schleswig-Holstein",
"Thuringia"
]
    }
    },
    components: {
   Select2,
    },
    methods:{
        ...mapActions(["fetchdata","EditData"]),
        fetch(){
this.fetchdata({db:"CompanySettings",mt:"SetCompany"})
        },
        edit(){
            console.log({db:"CompanySettings",mt:"SetCompany",id:this.GetComp.id,data:this.GetComp});
this.EditData({db:"CompanySettings",mt:"ChangeCompany",id:this.GetComp.id,data:this.GetComp})
        }
    },
    computed:{
        ...mapGetters(["Getcompany"]),
GetComp(){
return this.Getcompany
}
    },
    mounted() {
this.fetch()
    },
    name: 'settings'
  }
</script>