var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-0"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"action":"#"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('h4',{staticClass:"card-title"},[_vm._v("Personal Details")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("State")]),_c('div',{staticClass:"col-lg-9"},[_c('vue-select',{attrs:{"options":_vm.horizontalstate}})],1)]),_vm._m(4)]),_c('div',{staticClass:"col-xl-6"},[_c('h4',{staticClass:"card-title"},[_vm._v("Personal details")]),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("Address")]),_c('div',{staticClass:"col-lg-9"},[_c('input',{staticClass:"form-control m-b-20",attrs:{"type":"text"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('vue-select',{attrs:{"options":_vm.horizontalcountry}})],1),_vm._m(8)]),_vm._m(9)])])])])]),_vm._m(10)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("Two Column Horizontal Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("First Name")]),_c('div',{staticClass:"col-lg-9"},[_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("Last Name")]),_c('div',{staticClass:"col-lg-9"},[_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("Password")]),_c('div',{staticClass:"col-lg-9"},[_c('input',{staticClass:"form-control",attrs:{"type":"password"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("About")]),_c('div',{staticClass:"col-lg-9"},[_c('textarea',{staticClass:"form-control",attrs:{"rows":"4","cols":"5","placeholder":"Enter message"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("Name")]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"First Name"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Last Name"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("Email")]),_c('div',{staticClass:"col-lg-9"},[_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-3 col-form-label"},[_vm._v("Phone")]),_c('div',{staticClass:"col-lg-9"},[_c('input',{staticClass:"form-control",attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"ZIP code"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"State/Province"}})]),_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"City"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-end"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Submit")])])
}]

export { render, staticRenderFns }