<template>

  <div class="profile">

    <div class="main-wrapper">

      <main-header></main-header>

      <sidebar></sidebar>

      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">

          <headerprofile />

          <profilecardfirst :data="personel" />

          <!-- <profilecardsecond /> -->

          <!-- <profilecontent /> -->

        </div>
        <!-- /Page Content -->

        <modalprofile :data="data" />

      </div>
      <!-- /Page Wrapper -->

    </div>

  </div>

</template>
<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      data: this.$route.params.data,
      personel: null,
    }
  },
  methods: {
    ...mapActions(["personelGetir"])

  },
  computed: {
    // bilgi() {

    //   return this.personel
    // }
  },

  mounted() {
    this.$store.dispatch("personelGetir", { id: this.data }).then((a) => {
      console.log("SONUÇ", a.data())
      this.personel = { id: a.id, ...a.data() }
    })
    // this.personelGetir({ id: this.data }).then((a) => {
    //   console.log("SONUÇ", a.data())
    //   this.personel = { id: a.id, ...a.data() }
    // })
    // console.log("PERSONEL", this.personelGetir({ payload: { id: this.data } }))

  },
  name: 'profile'
}
</script>