<template>
  <div class="holidays">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <!-- Page Content -->
        <div class="content container-fluid">
          <headerholidays />

          <holidaycontent @Holday="holidays" @deleteHolidays="delHolidays" @editHolidays="editHoliday" />
        </div>
        <!-- /Page Content -->

        <modalholiday :editHolidays="editHolidays" :deleteHolidays="deleteHolidays" :holidays="holidays" />
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      holidays: [],
      deleteHolidays: {},
      editHolidays: {}
    };
  },
  components: {},
  methods: {

    ...mapActions(["sorgu"]),
    getHolidays(e) {
      this.holidays = e;
    },
    delHolidays(b) {
      this.deleteHolidays = b;
    },
    editHoliday(c) {
      this.editHolidays = c;
    }
  },
  computed: {



  },
  mounted() {

    //   this.holidaysGetir()
    const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
    this.sorgu({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] })
    // setTimeout(() => {
    //   // this.GetTabloDinle[0].veri()
    //   delete this.GetTabloDinle[0]
    // }, 20000);

  },
  destroyed() {
    // console.log("TABLOGETİR", this.GetTabloDinle)
    // // this.GetTabloDinle[0].veri()
    // //delete this.GetTabloDinle[0]
    // console.log("TABLOSONU", this.GetTabloDinle)
  },
  name: "holidays",
};
</script>
