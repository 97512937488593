<template>
  <div class="leaves">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">

          <headerleave />

          <leavewidget />

          <leavefilter />

          <leavecontent @editLeave="editLeaves" />

        </div>
        <!-- /Page Content -->

        <modalleave :editLeave="editLeave" />

      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {

  data() {
    return {
      // Leave: null,
      editLeave: {},
      // deleteLeave: null

    }
  },
  components: {

  },
  methods: {
    ...mapActions(["tumPersonel", "leavesGetir"]),
    editLeaves(e) {
      this.editLeave = e

    }

  },
  mounted() {
    this.tumPersonel();
    this.leavesGetir();
  },

  name: 'leaves'
}
</script>