<template>
  <div class="training">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">

          <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Training</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/index">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item active">Training</li>

                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_training"><i
                    class="fa fa-plus"></i> Add New </a>
              </div>
            </div>
          </div>
          <!-- /Page Header -->

          <maintrtable @trainingList="training" />

        </div>
        <!-- /Page Content -->

        <modalmaintraining />

      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Select2 from 'v-select2-component';
import { mapGetters, mapActions } from 'vuex';
import DatePicker from "vue2-datepicker";

/* eslint-disable */
export default {
  name: "clientsList",
  data() {
    return {
      classList: {},
      training: {
        CompanyUID: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        Description: "",
        Email: "",
        Employees: [],
        EndDate: "",
        StartDate: "",
        Trainer: "",
        TrainingCost: "",
        TrainingType: "",
        Status: true,
      },
      addtrainingStatus: ["Active", "Inactive"],
      edittrainingStatus: ["Active", "Inactive"],
    };
  },
  components: {
    Select2,
    DatePicker,
  },
  methods: {
    ...mapActions(["AddData", "fetchdata", "EditData", "DeleteData", "GetTrainingData"]),
    fetch() {
      this.fetchdata({ db: "Training", mt: "SetTraining" });
    },
    addTraining() {
      this.AddData({
        db: "Training",
        mt: "SetTraining",
        veri: this.training,
      }).then(() => {
        this.GetTrainingData()
        $(".close").click()
      });

    },
    trainingList(e) {
      this.training = e;
    },
    trainingDelete(e) {

    },

  },
  computed: {
    ...mapGetters(["GetTraining"]),
    getData() {
      let arr = []
      this.GetTraining.forEach(e => {
        arr.push(e.Type)
      })
      return [...new Set(arr)]
    },
  },

  mounted() {
    this.GetTrainingData()
    this.fetch()

  },
  name: 'training'
}
</script>