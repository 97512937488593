var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane show active",attrs:{"id":"tab_additions"}},[_vm._m(0),_c('div',{staticClass:"payroll-table card"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover table-radius"},[_vm._m(1),_c('tbody',_vm._l((_vm.payrolladd),function(item){return _c('tr',{key:item.id},[_c('th',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.category))]),_c('td',[_vm._v(_vm._s(item.default))]),_vm._m(2,true)])}),0)])])])]),_c('div',{staticClass:"tab-pane",attrs:{"id":"tab_overtime"}},[_vm._m(3),_c('div',{staticClass:"payroll-table card"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover table-radius"},[_vm._m(4),_c('tbody',_vm._l((_vm.payrollovertime),function(item){return _c('tr',{key:item.id},[_c('th',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.rate))]),_vm._m(5,true)])}),0)])])])]),_c('div',{staticClass:"tab-pane",attrs:{"id":"tab_deductions"}},[_vm._m(6),_c('div',{staticClass:"payroll-table card"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover table-radius"},[_vm._m(7),_c('tbody',_vm._l((_vm.payrolldeduction),function(item){return _c('tr',{key:item.id},[_c('th',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.category))]),_vm._m(8,true)])}),0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-end mb-4 clearfix"},[_c('button',{staticClass:"btn btn-primary add-btn",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#add_addition"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add Addition")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Default/Unit Amount")]),_c('th',{staticClass:"text-end"},[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-end"},[_c('div',{staticClass:"dropdown dropdown-action"},[_c('a',{staticClass:"action-icon dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#edit_addition"}},[_c('i',{staticClass:"fa fa-pencil m-r-5"}),_vm._v(" Edit")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#delete_addition"}},[_c('i',{staticClass:"fa fa-trash-o m-r-5"}),_vm._v(" Delete")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-end mb-4 clearfix"},[_c('button',{staticClass:"btn btn-primary add-btn",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#add_overtime"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add Overtime")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Rate")]),_c('th',{staticClass:"text-end"},[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-end"},[_c('div',{staticClass:"dropdown dropdown-action"},[_c('a',{staticClass:"action-icon dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#edit_overtime"}},[_c('i',{staticClass:"fa fa-pencil m-r-5"}),_vm._v(" Edit")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#delete_overtime"}},[_c('i',{staticClass:"fa fa-trash-o m-r-5"}),_vm._v(" Delete")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-end mb-4 clearfix"},[_c('button',{staticClass:"btn btn-primary add-btn",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#add_deduction"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add Deduction")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Default/Unit Amount")]),_c('th',{staticClass:"text-end"},[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-end"},[_c('div',{staticClass:"dropdown dropdown-action"},[_c('a',{staticClass:"action-icon dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#edit_deduction"}},[_c('i',{staticClass:"fa fa-pencil m-r-5"}),_vm._v(" Edit")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#delete_deduction"}},[_c('i',{staticClass:"fa fa-trash-o m-r-5"}),_vm._v(" Delete")])])])])
}]

export { render, staticRenderFns }