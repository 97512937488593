<template>
  <!-- Add Department Modal -->
  <div>

    <div id="add_department" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Department</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label>Department Name <span class="text-danger">*</span></label>
                <input v-model="Dept.DepartmentName" class="form-control" type="text">
              </div>
              <div class="submit-section">
                <button @click.prevent="addDep" class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Department Modal -->

    <!-- Edit Department Modal -->
    <div id="edit_department" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Department</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editDep">
              <div class="form-group">
                <label>Department Name <span class="text-danger">*</span></label>
                <input class="form-control" name="DepartmentName" :value="editdatas.DepartmentName" type="text">
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Department Modal -->

    <!-- Delete Department Modal -->
    <div class="modal custom-modal fade" id="delete_department" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Department</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">

                <div class="col-6" @click.prevent="deleteDep">
                  <a class="btn btn-primary continue-btn">Delete</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Department Modal -->
</template>
<script>
import { mapActions } from 'vuex'
import { jsonEval } from "@firebase/util";

export default {

  props: {
    editdatas: {
      type: Object,
    },
    deletedatas:
    {
      type: Object,
    },
    departman: {
      type: Array
    }
  },
  data() {
    return {
      Dept: {
        DepartmentName: "",
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid
      },

      EditDepartmanName: "this.editdatas.DepartmentName"
    }
  },
  methods: {
    ...mapActions(["DeleteData", "EditData", "AddData", "departmanGetir"]),
    deleteDep() {
      this.DeleteData({ db: "Department", mt: "DeleteDept", data: this.deletedatas }).then(() => {
        this.departmanGetir()
        $(".close").click()

      });
    },
    addDep() {
      this.AddData({ db: "Department", mt: "SetDept", veri: this.Dept }).then(() => {
        this.departmanGetir()
        $(".close").click()

      });

    },
    editDep(e) {
      const formData = new FormData(e.target);
      const formProps = Object.fromEntries(formData);
      this.EditData({ db: "Department", mt: "ChangeDept", data: formProps, id: this.editdatas.id }).then(() => {
        this.departmanGetir()
        $(".close").click()

      });

    }

  }
}
</script>
