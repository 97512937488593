var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-table"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-nowrap custom-table mb-0"},[_vm._m(1),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_c('router-link',{attrs:{"to":"/job-details"}},[_vm._v("Web Developer")])],1),_c('td',[_vm._v("Development")]),_c('td',[_vm._v("3 Mar 2019")]),_c('td',[_vm._v("31 May 2019")]),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_c('router-link',{attrs:{"to":"/job-details"}},[_vm._v("Web Designer")])],1),_c('td',[_vm._v("Designing")]),_c('td',[_vm._v("3 Mar 2019")]),_c('td',[_vm._v("31 May 2019")]),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('tr',[_c('td',[_vm._v("3")]),_c('td',[_c('router-link',{attrs:{"to":"/job-details"}},[_vm._v("Android Developer")])],1),_c('td',[_vm._v("Android")]),_c('td',[_vm._v("3 Mar 2019")]),_c('td',[_vm._v("31 May 2019")]),_vm._m(8),_vm._m(9),_vm._m(10)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title mb-0"},[_vm._v("Applied Jobs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Job Title")]),_c('th',[_vm._v("Department")]),_c('th',[_vm._v("Start Date")]),_c('th',[_vm._v("Expire Date")]),_c('th',{staticClass:"text-center"},[_vm._v("Job Type")]),_c('th',{staticClass:"text-center"},[_vm._v("Status")]),_c('th',{staticClass:"text-center"},[_vm._v("Actions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" Full Time ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" Open ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"dropdown dropdown-action"},[_c('a',{staticClass:"action-icon dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#delete_employee"}},[_c('i',{staticClass:"fa fa-trash-o m-r-5"}),_vm._v(" Delete")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" Part Time ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-success"}),_vm._v(" Closed ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"dropdown dropdown-action"},[_c('a',{staticClass:"action-icon dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#delete_employee"}},[_c('i',{staticClass:"fa fa-trash-o m-r-5"}),_vm._v(" Delete")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" Internship ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"action-label"},[_c('a',{staticClass:"btn btn-white btn-sm btn-rounded",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-dot-circle-o text-danger"}),_vm._v(" Cancelled ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"dropdown dropdown-action"},[_c('a',{staticClass:"action-icon dropdown-toggle",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('i',{staticClass:"material-icons"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-bs-toggle":"modal","data-bs-target":"#delete_employee"}},[_c('i',{staticClass:"fa fa-trash-o m-r-5"}),_vm._v(" Delete")])])])])
}]

export { render, staticRenderFns }